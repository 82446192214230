import { IFaq } from "../../Types/faq.interface";
import * as Yup from "yup";

const initialValues: IFaq = {
  description: [""],
  heading: "",
  courseId: "",
};

const validationSchema = Yup.object().shape({
  courseId: Yup.string().required("Course name is required"),
  heading: Yup.string().required("Faq heading is required"),
  description: Yup.array()
    .of(Yup.string().required("Description cannot be empty"))
    .min(1, "At least one description is required")
    .required("Description is required"),
});

export const course_faq_form = {
  initialValues,
  validationSchema,
};
