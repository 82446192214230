import { useMutation } from "@tanstack/react-query";
import { request } from "../../../services/axios.service";
import apiurls from "../../apiurls";

const create = async (data: IJoyresult) => {
  const res: TServerResponse = await request({
    url: apiurls.joyResultApi.CREATE_JOY_RESULT,
    method: "POST",
    data,
  });
  return res;
};

export const useCreateJoyResult = () => {
  return useMutation({
    mutationFn: create,
  });
};
