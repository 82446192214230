import { Box, Text } from "@mantine/core";
import React, { useMemo, useState } from "react";
import PaginatedTable from "../../components/paginated-table/PaginatedTable";
import { newsletterTable } from "../../constants/tables/newsletter.table";
import { TTableColumns } from "../../Types/table.interface";
import { useGetNewsletters } from "../../hooks/getNewsletter.query";
import { CONSTANTS } from "../../constants/index.constant";

const NewsletterPage = () => {
  const [page, setPage] = useState(1);
  const { data, isLoading } = useGetNewsletters({
    page,
    limit: CONSTANTS.PAGE_LIMIT,
  });

  const details = useMemo(() => {
    if (!isLoading && data?.status === "success") {
      return (
        (data?.data as []).map((item, index) => ({
          ...(item as unknown as object),
          sno: ((page ?? 1) - 1) * CONSTANTS.PAGE_LIMIT + (index + 1),
        })) ?? []
      );
    }
    return [];
  }, [isLoading, data]);

  return (
    <Box pr={24}>
      <Text fw={600} fz={"h3"} my={24}>
        NewsLetters
      </Text>
      <Box>
        <PaginatedTable
          columns={newsletterTable as TTableColumns<unknown>[]}
          data={details}
          isLoading={isLoading}
          keyExtractor={(e) => {
            const key = e as { _id: string };
            return key._id;
          }}
          paginationProps={{
            page,
            setPage,
            totalDocuments: Number(data?.extraData ?? "0"),
            pageLimit: CONSTANTS.PAGE_LIMIT,
          }}
        />
      </Box>
    </Box>
  );
};

export default NewsletterPage;
