import { useMutation } from "@tanstack/react-query";
import apiurls from "./apiurls";
import { request } from "../services/axios.service";
type TProps = {
  username: string;
  password: string;
};
const adminLogin = async (data: TProps) => {
  const res: TServerResponse = await request({
    url: apiurls.LOGIN,
    method: "POST",
    data,
  });
  return res;
};

export const useAdminLogin = () => {
  return useMutation({
    mutationFn: adminLogin,
  });
};
