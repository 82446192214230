import { useMutation, useQueryClient } from "@tanstack/react-query";
import apiurls from "../../apiurls";
import { request } from "../../../services/axios.service";

const deleteBlog = async (id: string) => {
  const res: TServerResponse = await request({
    url: apiurls.DELETE_BLOGS,
    method: "POST",
    data: { id: id },
  });
  return res;
};

export const useDeleteBlog = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: deleteBlog,
    onSuccess: () => {
      // Refetch the allBlog query
      queryClient.invalidateQueries({ queryKey: ["blogs"] }); // Replace "allBlog" with your actual query key
    },
  });
};
