import { AppShell, ScrollArea } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { memo } from "react";
import Header from "./Header/Header";
import TheNavbar from "./TheNavbar";
import { Outlet } from "react-router-dom";

const TheLayout = () => {
  const [opened, { toggle }] = useDisclosure();

  return (
    <AppShell
      header={{ height: 78 }}
      navbar={{
        width: 260,
        breakpoint: "sm",
        collapsed: { mobile: !opened },
      }}
      padding="md"
    >
      <AppShell.Header>
        <Header opened toggle={toggle} />
      </AppShell.Header>

      <AppShell.Navbar p="md" component={ScrollArea}>
        <TheNavbar opened={opened} setOpened={toggle} />
      </AppShell.Navbar>

      <AppShell.Main>
        <Outlet />
      </AppShell.Main>
    </AppShell>
  );
};

export default memo(TheLayout);
