import { createBrowserRouter } from "react-router-dom";
import { ROUTES } from "../enum/routes";
import AdminLogin from "../pages/auth/AdminLogin";
import StudentsPage from "../pages/students/StudentsPage";
import Dashboard from "../pages/dashboard/Dashboard";
import NewsletterPage from "../pages/newletter/NewsletterPage";
import ContactUsPage from "../pages/contact-us/ContactUsPage";
import Blogs from "../pages/blogs/Blogs";
import AddBlog from "../pages/blogs/AddBlog";
import YoutubePlayList from "../pages/youtube-playlist/YoutubePlayList";
import JoyResult from "../pages/joy-result/JoyResult";
import Alumnis from "../pages/alumnis/Alumnis";
import LearningModule from "../pages/learning-module/LearningModule";
import CourseReviews from "../pages/course-reviews/CourseReviews";
import Courses from "../pages/courses/Courses";
import AddCourse from "../pages/courses/AddCourse";
import Faq from "../pages/faq/Faq";
import LandingPage from "../pages/landing-page/LandingPage";
import GoogleTokenCreate from "../pages/google-token-create/GoogleTokenCreate";
import AuthCallback from "../pages/google-token-create/auth-callback/AuthCallback";
import Transaction from "../pages/transaction/Transaction";
import TheLayout from "../layout/TheLayout";

export const appRouter = createBrowserRouter([
  {
    path: ROUTES.ADMIN_LOGIN,
    element: <AdminLogin />,
  },
  {
    path: "/",
    element: <TheLayout />,
    children: [
      {
        path: ROUTES.ADMINDASHBOARD,
        element: <Dashboard />,
      },
      {
        path: ROUTES.STUDENTS,
        element: <StudentsPage />,
      },
      {
        path: ROUTES.NEWSLETTER,
        element: <NewsletterPage />,
      },
      {
        path: ROUTES.CONTACT_US,
        element: <ContactUsPage />,
      },
      {
        path: ROUTES.REGISTERED_STUDENTS,
        element: <Transaction />,
      },
      {
        path: ROUTES.ALUMNIS,
        element: <Alumnis />,
      },
      {
        path: ROUTES.BLOGS,
        element: <Blogs />,
        children: [
          {
            path: ROUTES.ADD_BLOG,
            element: <AddBlog />,
          },
          {
            path: ROUTES.EDIT_BLOG,
            element: <AddBlog />,
          },
        ],
      },
      {
        path: "/home",
        children: [
          {
            path: ROUTES.LANDING_PAGE,
            element: <LandingPage />,
          },
          {
            path: ROUTES.YOUTUBE_PLAYLIST,
            element: <YoutubePlayList />,
          },
          {
            path: ROUTES.JOY_RESULT,
            element: <JoyResult />,
          },
        ],
      },
      {
        path: "/course",
        children: [
          {
            path: ROUTES.COURSE_REVIEWS,
            element: <CourseReviews />,
          },
          {
            path: ROUTES.LEARNING_MODULE,
            element: <LearningModule />,
          },
          {
            path: ROUTES.FAQ,
            element: <Faq />,
          },
        ],
      },

      {
        path: ROUTES.COURSES,
        element: <Courses />,
        children: [
          {
            path: ROUTES.ADD_COURSE,
            element: <AddCourse />,
          },
          {
            path: ROUTES.EDIT_COURSE,
            element: <AddCourse />,
          },
        ],
      },

      {
        path: ROUTES.GOOGLE_TOKEN_CREATE,
        element: <GoogleTokenCreate />,
      },
      {
        path: "/auth",
        // element: <GoogleTokenCreate />,
        children: [
          {
            path: "callback",
            element: <AuthCallback />,
          },
        ],
      },
    ],
  },
]);
