import React from "react";
import { IBlog, TTableColumns } from "../../Types/table.interface";
import BlogImage from "../../components/BlogImage";
import CommonActionBar from "../../components/common/ActionBar/CommonActionBar";
import moment from "moment";

export const generateBlogsTable = (
  deleteBlog: any,
  navigate: (path: string) => void
): TTableColumns<IBlog>[] => [
  {
    key: "sno",
    label: "S No",
  },
  {
    key: "title",
    label: "Title",
    renderCell: (value) => value.cardData.title,
  },
  {
    key: "description",
    label: "Description",
    renderCell: (value) => value.cardData.description,
  },
  {
    key: "name",
    label: "Author",
    renderCell: (value) => value.cardData.name,
  },
  {
    key: "image",
    label: "Card Image",
    renderCell: (value) => <BlogImage value={value} />,
  },
  {
    key: "date",
    label: "Date",
    renderCell: (value) =>
      moment(value.createdAt).format("DD-MMM-YYYY - hh:mm:ss A"),
  },
  {
    key: "updated",
    label: "Updated At",
    renderCell: (value) =>
      moment(value.updatedAt).format("DD-MMM-YYYY - hh:mm:ss A"),
  },
  {
    key: "action",
    label: "Actions",
    renderCell: (value) => (
      <CommonActionBar
        value={value}
        name="Blog"
        handleDelete={() => deleteBlog(value._id ?? "")}
        handleEdit={() => navigate(`/blogs/edit-blog/${value._id}`)}
      />
    ),
  },
];
