import React from 'react'
import { MainLinks } from './MainLinks'

interface ISidebar {
    opened: boolean;
    setOpened: React.Dispatch<React.SetStateAction<boolean>>;
  }

const TheNavbar = (props: ISidebar) => {
    const {  setOpened } = props;
  return (
    <MainLinks toggle={() => setOpened((e) => !e)} />
  )
}

export default TheNavbar