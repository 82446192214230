import React from "react";
import "./App.css";
import "@mantine/core/styles.css";
import "@mantine/notifications/styles.css";
import { MantineProvider } from "@mantine/core";
import { Notifications } from "@mantine/notifications";
import { ModalsProvider } from "@mantine/modals";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { RouterProvider } from "react-router-dom";
import { appRouter } from "./routes/appRoutes";
import createStore from "react-auth-kit/createStore";
import AuthProvider from "react-auth-kit/AuthProvider";
import "@mantine/tiptap/styles.css";
export const queryClient = new QueryClient();

const store = createStore({
  authName: "_auth",
  authType: "cookie",
  cookieDomain: window.location.hostname,
  cookieSecure: window.location.protocol === "https:",
});

function App() {
  return (
    <AuthProvider store={store}>
      <MantineProvider>
        <QueryClientProvider client={queryClient}>
        <ModalsProvider>
          <Notifications />
          <RouterProvider router={appRouter} />
        </ModalsProvider>
        </QueryClientProvider>
      </MantineProvider>
    </AuthProvider>
  );
}

export default App;
