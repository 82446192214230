import { useQuery } from "@tanstack/react-query";
import { request } from "../../../services/axios.service";
import apiurls from "../../apiurls";
interface IProps {
  page: number;
  limit: number;
  search?: string;
  isKycDone?: string;
}
const get = async (data: IProps) => {
  const res: TServerResponse = await request({
    url: apiurls.students.GET_ALL_STUDENTS,
    method: "GET",
    params: data,
  });
  return res;
};

export const useGetAllStudents = (data: IProps) => {
  return useQuery({
    queryKey: [
      "get-all-students",
      data.page,
      data.limit,
      data.search,
      data.isKycDone,
    ],
    queryFn: () => get(data),
  });
};
