import { useMutation, useQueryClient } from "@tanstack/react-query";
import { request } from "../../../services/axios.service";
import apiurls from "../../apiurls";

const deleteFaq = async ({ _id }: { _id: string }) => {
  const res: TServerResponse = await request({
    url: apiurls.faqApi.DELETE_FAQ + `/${_id}`,
    method: "POST",
  });
  return res;
};

export const useDeleteFaq = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: deleteFaq,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["get-all-faq"] });
    },
  });
};
