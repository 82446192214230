import { Box, Modal, TextInput } from "@mantine/core";
import React, { useEffect } from "react";
import FButton from "../../../../components/ui/Button/FButton";
import { useForm, yupResolver } from "@mantine/form";
import { formValue } from "../../../../form-values";
import { notifications } from "@mantine/notifications";
import { IoverallHome } from "../../../../Types/overallHomePage.interface";
import { useAddHomePageDetails } from "../../../../hooks/overall-home-page/mutations/useAddHomePageDetails.mutation";
import { useEditHomePageDetails } from "../../../../hooks/overall-home-page/mutations/useEditHomePageDetails.mutation";

interface Ioverall {
  data: {
    opened: boolean;
    close: () => void;
    value?: IoverallHome;
    refetch: any;
  };
}

const LandingPageModel: React.FC<Ioverall> = ({ data }) => {
  const { close, opened, value, refetch } = data;
  const isEditMode = !!value;
  const { mutateAsync, isPending } = useAddHomePageDetails();
  const { mutateAsync: updatePlaylist, isPending: isUpdating } =
    useEditHomePageDetails();
  const form = useForm<IoverallHome>({
    initialValues: formValue.overallHomePage_form.initialValues,
    validate: yupResolver(formValue.overallHomePage_form.validationSchema),
  });

  useEffect(() => {
    if (value) {
      form.setValues({ ...value }); // Set form values for editing
    }
  }, [value]);

  const handleAdminSubmit = async (e: IoverallHome) => {
    try {
      console.log("handleadminsubmit", e);
      const res = isEditMode
        ? await updatePlaylist({ id: e._id ?? "", data: e }) // Update if in edit mode
        : await mutateAsync(e);
      if (res.statusCode === 200) {
        notifications.show({ message: res.message, color: "green" });
        form.reset();
        close();
        refetch();
      }
    } catch (error: any) {
      console.log("error", error);
      notifications.show({ message: error.message, color: "red" });
    }
  };

  return (
    <Modal
      opened={opened}
      onClose={close}
      title={isEditMode ? "Edit Home page Details" : "Add Home Page Details"}
      closeOnClickOutside={false}
      size={"lg"}
    >
      <form
        onSubmit={form.onSubmit(handleAdminSubmit)}
        style={{ width: "100%" }}
      >
        <Box>
          <TextInput
            mb={12}
            required
            label={"Enter enrolled students"}
            placeholder={"Eg. 500+"}
            {...form.getInputProps("enrolledStudents")}
          />
          <TextInput
            required
            mb={12}
            label={"Enter Total Hours"}
            placeholder={"Eg. 200+"}
            {...form.getInputProps("totalLectureHours")}
          />
          <TextInput
            required
            mb={12}
            label={"Enter Average Ratings"}
            placeholder={"Eg. 4.0/5"}
            {...form.getInputProps("averageRatings")}
          />
          <TextInput
            required
            mb={12}
            label={"Enter Linkedin Follower count"}
            placeholder={"Eg. 100K+"}
            {...form.getInputProps("linkedinSubscribers")}
          />

          <TextInput
            required
            mb={12}
            label={"Enter Youtube Subscriber Count"}
            placeholder={"Eg. 150K+"}
            {...form.getInputProps("youtubeSubscribers")}
          />

          <FButton
            label={isEditMode ? "Edit Details" : "Add Details"}
            type="submit"
            width="100%"
            loading={isEditMode ? isUpdating : isPending}
          />
        </Box>
      </form>
    </Modal>
  );
};

export default LandingPageModel;
