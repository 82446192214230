import { useQuery } from "@tanstack/react-query";
import { request } from "../../../services/axios.service";
import apiurls from "../../apiurls";

type TProps = {
  page: number;
  limit: number;
};
const getCourses = async (data: TProps) => {
  const res: TServerResponse = await request({
    url: apiurls.coursesApi.GET_PAGINATED_COURSES,
    method: "GET",
    params: data,
  });
  return res;
};

export const useGetPaginatedCourses = (data: TProps) => {
  return useQuery({
    queryKey: ["courses", data],
    queryFn: () => getCourses(data),
  });
};
