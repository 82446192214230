import { Link } from "react-router-dom";
import { TTableColumns } from "../../Types/table.interface";
import { ICourseReview } from "../../Types/courseReview.interface";
export const courseReviewcolumn: TTableColumns<ICourseReview>[] = [
  {
    key: "sno",
    label: "S. No.",
    minWidth:100
  },
  {
    key: "name",
    label: "Name",
    minWidth:250
  },
  {
    key: "designation",
    label: "Designation",
    minWidth:250
  },
  {
    key: "description",
    label: "Description",
    minWidth:300
  },
  {
    key: "linkedinUrl",
    label: "Linkedin Url",
    minWidth:300,
    renderCell: (value) => (
    <>
    {
      value.linkedinUrl ? <Link to={value.linkedinUrl} target="_blank"> {value.linkedinUrl} </Link> : <span>Url not found</span>
    }
    
    </>
  )
  },
  {
    key: "imageUrl",
    label: "Linkedin Profile (Images)",
    minWidth:300,
    renderCell: (value) => (
      <>
      {
        value.imageUrl ?
        <img src={value.imageUrl as string} alt="" width={200}/> :<span>Linkedin Profile (Images) Url not found</span>
      }
      </>
    )
  },

];
