import {
  Button,
  DefaultMantineColor,
  MantineSpacing,
  StyleProp,
} from "@mantine/core";
import React from "react";
import classes from "./index.module.css";
interface IButton {
  label?: string;
  type?: "submit" | "button";
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  disabled?: boolean;
  children?: React.ReactNode;
  classNames?: string;
  color?: DefaultMantineColor | "#24a94a";
  width?: string;
  textAlign?: StyleProp<CanvasTextAlign | undefined>;
  px?: StyleProp<MantineSpacing>;
  py?: StyleProp<MantineSpacing>;
  loading?: boolean;
}
const FButton: React.FC<IButton> = ({
  type = "button",
  onClick,
  label,
  disabled,
  children,
  color,
  width,
  textAlign,
  px,
  py,
  loading,
}) => {
  return (
    <Button
      onClick={onClick}
      type={type}
      classNames={{ root: classes.root }}
      disabled={disabled ?? false}
      color={color}
      w={width}
      ta={textAlign}
      size="sm"
      px={px}
      py={py}
      loading={loading}
    >
      {label ?? children}
    </Button>
  );
};

export default FButton;
