import {
  ActionIcon,
  Button,
  Flex,
  Paper,
  Text,
  TextInput,
  TextInputProps,
} from "@mantine/core";
import { UseFormReturnType } from "@mantine/form";
import { IconBold, IconTrash } from "@tabler/icons-react";
import React, { memo } from "react";
import { ILearningModule } from "./learningModule.initialvalue";
import { randomId } from "@mantine/hooks";

interface Props {
  fromHandler: UseFormReturnType<
    ILearningModule,
    (values: ILearningModule) => ILearningModule
  >;
  index: number;
  showTrashIcon: boolean;
}

const ModuleField: React.FC<Props> = ({
  fromHandler,
  index,
  showTrashIcon,
}) => {
  const {
    removeListItem,
    getInputProps,
    getValues,
    insertListItem,
    setFieldValue,
  } = fromHandler;

  return (
    <Paper shadow="sm" p="sm" my={12} pos={"relative"}>
      <Text mb={4}>Module {index + 1}</Text>
      <Heading inputProps={getInputProps(`modules.${index}.title`)} />
      <Text mt={8}>Points</Text>
      {getValues().modules[index].lists.map((e, i, all) => {
        return (
          <List
            key={e.key}
            inputProps={getInputProps(`modules.${index}.lists.${i}.list`)}
            showTrashIcon={all.length > 1}
            onTrashClick={() => removeListItem(`modules.${index}.lists`, i)}
            bold={!!e.bold}
            onBoldClick={(e) =>
              setFieldValue(`modules.${index}.lists.${i}.bold`, e)
            }
          />
        );
      })}
      <Button
        mt={12}
        onClick={() => {
          insertListItem(`modules.${index}.lists`, {
            title: "",
            key: randomId(),
          });
        }}
      >
        Add
      </Button>

      {showTrashIcon && (
        <IconTrash
          color="red"
          style={{ position: "absolute", top: 8, right: 8, cursor: "pointer" }}
          onClick={() => {
            removeListItem("modules", index);
          }}
        />
      )}
    </Paper>
  );
};

export default memo(ModuleField);

interface IHeading {
  inputProps: TextInputProps;
}

const Heading: React.FC<IHeading> = ({ inputProps }) => {
  return (
    <TextInput
      width={"100%"}
      placeholder="Enter module heading"
      {...inputProps}
    />
  );
};

const List: React.FC<
  IHeading & {
    showTrashIcon: boolean;
    onTrashClick: () => void;
    onBoldClick: (e: boolean) => void;
    bold: boolean;
  }
> = ({ inputProps, showTrashIcon, onTrashClick, bold, onBoldClick }) => {
  return (
    <Flex w={"100%"} mt={8} gap={12} align={"flex-start"}>
      <TextInput
        placeholder="Enter point"
        style={{ flex: 1 }}
        {...inputProps}
      />
      <ActionIcon
        variant={bold ? "filled" : "outline"}
        aria-label="Settings"
        size={"lg"}
        onClick={() => onBoldClick(!bold)}
      >
        <IconBold style={{ width: "70%", height: "70%" }} stroke={1.5} />
      </ActionIcon>
      {showTrashIcon && (
        <ActionIcon
          variant="outline"
          aria-label="Delete"
          color="red"
          size={"lg"}
          onClick={onTrashClick}
        >
          <IconTrash style={{ width: "70%", height: "70%" }} stroke={1.5} />
        </ActionIcon>
      )}
    </Flex>
  );
};
