import { useMutation } from "@tanstack/react-query";

import apiurls from "../apiurls";
import { request } from "../../services/axios.service";

const deleteAlumni = async (id: string) => {
  const res: TServerResponse = await request({
    url: apiurls.alumniUrls.DELETE_ALUMNI,
    method: "DELETE",
    data: {
      id,
    },
  });
  return res;
};

export default () => {
  return useMutation({
    mutationFn: deleteAlumni,
  });
};
