import { Flex, Image } from "@mantine/core";
import { useForm, yupResolver } from "@mantine/form";
import useSignIn from "react-auth-kit/hooks/useSignIn";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "../../enum/routes";
import { memo } from "react";
import { adminLoginValidation } from "../../validation/admin.validation";
import Input from "../../components/ui/Input/Input";
import FPasswordInput from "../../components/ui/FPasswordInput/FPasswordInput";
import FButton from "../../components/ui/Button/FButton";
import { IMAGES } from "../../assets/images";
import { useAdminLogin } from "../../hooks/adminLogin.mutation";
const AdminLogin = () => {
  const form = useForm<TAdminForm>({
    initialValues: {
      email: "",
      password: "",
    },
    validate: yupResolver(adminLoginValidation),
  });
  const signIn = useSignIn();
  const navigate = useNavigate();
  const { mutateAsync, isPending } = useAdminLogin();
  const handleAdminSubmit = async (e: TAdminForm) => {
    const res = await mutateAsync({
      username: e.email,
      password: e.password,
    });
    if (res.statusCode === 200) {
      signIn({
        auth: {
          token: res.data.token,
          type: "Bearer",
        },
        userState: {
          name: res.data.authUserState.email,
        },
      });
      sessionStorage.setItem("authToken", res.data.token);
      form.reset();
      navigate(`${ROUTES.ADMINDASHBOARD}`);
    } else {
      console.log("ERROR");
    }
  };
  return (
    <form onSubmit={form.onSubmit(handleAdminSubmit)} style={{ width: "100%" }}>
      <Flex
        direction="column"
        pt={36}
        gap={24}
        align={"center"}
        mx={"auto"}
        maw={400}
        style={{ height: "100vh" }}
        justify={"center"}
      >
        <Image
          src={IMAGES.navbar_logo}
          alt="Logo"
          style={{ width: "40%", height: "auto" }}
        />
        <Input
          label="Enter Email Id"
          placeholder="Enter Email Id"
          formHandler={form.getInputProps("email")}
        />
        <FPasswordInput
          label="Enter Password"
          placeholder="Enter Password"
          formHandler={form.getInputProps("password")}
        />
        <FButton label="Login" type="submit" width="100%" loading={isPending} />
      </Flex>
    </form>
  );
};

export default memo(AdminLogin);
