import React, { memo } from "react";
import { Pagination } from "@mantine/core";
import { TPaging } from "../../../Types/table.interface";
import { CONSTANTS } from "../../../constants/index.constant";

const CustomPagination: React.FC<TPaging> = (props: TPaging) => {
  const { page, setPage, totalDocuments, pageLimit } = props;
  const limit = pageLimit && pageLimit !== 0 ? pageLimit : CONSTANTS.PAGE_LIMIT;
  if (totalDocuments && totalDocuments <= limit) {
    return null;
  }
  return (
    <Pagination
      value={page}
      onChange={setPage}
      size={"sm"}
      total={Math.ceil(totalDocuments / limit)}
      radius="sm"
      mt={20}
    />
  );
};

export default memo(CustomPagination);
