import { useMutation } from "@tanstack/react-query";

import apiurls from "../apiurls";
import { request } from "../../services/axios.service";

const changeAlumniStatus = async (data: { id: string; status: boolean }) => {
  const res: TServerResponse = await request({
    url: apiurls.alumniUrls.CHANGE_STATUS,
    method: "POST",
    data,
  });
  return res;
};

export default () => {
  return useMutation({
    mutationFn: changeAlumniStatus,
  });
};
