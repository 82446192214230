import { useMutation, useQueryClient } from "@tanstack/react-query";
import { request } from "../../../services/axios.service";
import apiurls from "../../apiurls";
import { IFaq } from "../../../Types/faq.interface";

const create = async (data: IFaq) => {
  const res: TServerResponse = await request({
    url: apiurls.faqApi.ADD_FAQ,
    method: "POST",
    data,
  });
  return res;
};

export const useCreateFaq = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: create,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["get-all-faq"] });
    },
  });
};
