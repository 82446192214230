import { useMutation, useQueryClient } from "@tanstack/react-query";
import apiurls from "../../apiurls";
import { request } from "../../../services/axios.service";
import { ICourses } from "../../../Types/courses.interface";

interface Iprops {
  data: ICourses;
  id: string;
}

const updateCourse = async ({ data }: { data: Iprops }) => {
  const res: TServerResponse = await request({
    headers: { "Content-Type": "multipart/form-data" },
    url: apiurls.coursesApi.EDIT_COURSE + `/${data.id}`,
    method: "POST",
    data: data.data,
  });
  return res;
};

export const useUpdateCourse = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: updateCourse,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["courses"] });
    },
  });
};
