import { randomId } from "@mantine/hooks";

export interface ILearningModule {
  _id?: string;
  downloadBrochure?: string;
  demoVideo?: string;
  modules: {
    title: string;
    lists: { list: string; bold?: boolean; key: string }[];
    key: string;
  }[];
}

export const learningModuleValues: ILearningModule = {
  modules: [
    { title: "", lists: [{ list: "", key: randomId() }], key: randomId() },
  ],
};
