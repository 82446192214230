import { useMutation, useQueryClient } from "@tanstack/react-query";
import apiurls from "../../apiurls";
import { request } from "../../../services/axios.service";
import { IoverallHome } from "../../../Types/overallHomePage.interface";

const createDetails = async ({
  id,
  data,
}: {
  id: string;
  data: IoverallHome;
}) => {
  const res: TServerResponse = await request({
    // headers: { "Content-Type": "multipart/form-data" },
    url: apiurls.overallHomePageApi.EDIT_DETAILS + `/${id}`,
    method: "POST",
    data,
  });
  return res;
};

export const useEditHomePageDetails = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: createDetails,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["home-page"] });
    },
  });
};
