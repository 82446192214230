import { yupResolver } from "@mantine/form";
import { array, boolean, object, string } from "yup";

export const learningModuleResolver = yupResolver(
  object().shape({
    _id: string(),
    downloadBrochure: string().url("Please enter valid url"),
    demoVideo: string().url("Please enter valid url"),
    modules: array().of(
      object().shape({
        title: string().required("title is required."),
        lists: array().of(
          object().shape({
            list: string().required("point is required."),
            bold: boolean(),
          })
        ),
      })
    ),
  })
);
