import { useQuery } from "@tanstack/react-query";
import { request } from "../../../services/axios.service";
import apiurls from "../../apiurls";

const get = async (data: { page: number; search: string; name: string }) => {
  const res: TServerResponse = await request({
    url: apiurls.GET_REGISTERED_STUDENTS,
    method: "GET",
    params: {
      page: data.page,
      search: data.search,
      courseName: data.name,
    },
  });
  return res;
};

export const useGetRegisteredStudents = (data: {
  page: number;
  search: string;
  name: string;
}) => {
  return useQuery({
    queryKey: ["get-registered-students", data.page, data.search, data.name],
    queryFn: () => get(data),
  });
};
