import { useQuery } from "@tanstack/react-query";

import apiurls from "../apiurls";
import { request } from "../../services/axios.service";

const getAlumniById = async (id: string) => {
  const res: TServerResponse = await request({
    url: apiurls.alumniUrls.GET_SINGLE_ALUMNI,
    method: "GET",
    params: {
      id,
    },
  });
  return res;
};

export default (id: string) => {
  return useQuery({
    queryKey: ["alumni-get-id", id],
    queryFn: () => getAlumniById(id),
  });
};
