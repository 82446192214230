import { Box, Burger, Button,  Flex,  Image } from "@mantine/core";
import React, { memo } from "react";
import useSignOut from "react-auth-kit/hooks/useSignOut";
import { useNavigate } from "react-router-dom";
import classes from "./index.module.css";
import { ROUTES } from "../../enum/routes";
import { IMAGES } from "../../assets/images";
import { IconLogout2 } from "@tabler/icons-react";

interface IHeader{
  opened:boolean,
  toggle:()=>void
}

const Header:React.FC<IHeader> = ({opened,toggle}) => {
  const signOut = useSignOut();
  const navigate = useNavigate();
  return (
    <Box className={classes.nav}>
     <Flex gap={10} align={"center"}>
     <Burger
        opened={opened}
        onClick={toggle}
        hiddenFrom="sm"
        size="sm"
      />
      <Box className={classes.imageContainer}>
        <Image
          src={IMAGES.navbar_logo}
          alt="navbar_logo"
          className={classes.img}
        />
      </Box>
     </Flex>
      <Button
        leftSection={<IconLogout2/>}
        onClick={() => {
          signOut();
          navigate(`${ROUTES.ADMIN_LOGIN}`);
        }}
      >
        Logout
      </Button>
    </Box>
  );
};

export default memo(Header);
