import { Link } from "react-router-dom";
import { IAlumni } from "../../Types/alumni.interface";
import { TTableColumns } from "../../Types/table.interface";
// import ActionButtonAlumni from "../../components/Alumnis/components/ActionButtonAlumni";
import { IMAGES } from "../../assets/images";
import ActionButtonAlumni from "../../pages/alumnis/components/ActionButtonAlumni";

export const alumnicolumn: TTableColumns<IAlumni>[] = [
  {
    key: "sno",
    label: "S no.",
    minWidth: 70,
  },
  {
    key: "name",
    label: "Name",
    renderCell: (value) => value.name,
    minWidth: 200,
  },
  {
    key: "position",
    label: "Position",
    renderCell: (value) => value.position,
    minWidth: 200,
  },
  {
    key: "description",
    label: "Description",
    renderCell: (value) => value.description,
    minWidth: 400,
  },
  {
    key: "rating",
    label: "Rating",
    renderCell: (value) => value.rating.toString(),
  },
  {
    key: "image",
    label: "Profile Image",
    renderCell: (value) => (
      <Link to={value.imageUrl} target="_blank">
        <img width={100} src={value.imageUrl} alt="profile-image" />
      </Link>
    ),
  },
  {
    key: "linkedinProfile",
    label: "Linkedin Profile",
    renderCell: (value) => (
      <Link target="_blank" to={value.linkedinUrl}>
        {value.linkedinUrl}
      </Link>
    ),
    minWidth: 200,
  },
  {
    key: "actions",
    label: "Actions",
    renderCell: (value) => (
      <ActionButtonAlumni isActive={value.isActive} id={value._id ?? ""} />
    ),
    // minWidth: 110,
  },
];

