import {
  Box,
  Modal,
  Select,
  Textarea,
  TextInput,
  Button,
  Flex,
} from "@mantine/core";
import React, { useEffect } from "react";
import FButton from "../../../../components/ui/Button/FButton";
import { useForm, yupResolver } from "@mantine/form";
import { formValue } from "../../../../form-values";
import { notifications } from "@mantine/notifications";
import { useCreateFaq } from "../../../../hooks/faq/mutations/useCreateFaq.mutation";
import { useUpdateFaq } from "../../../../hooks/faq/mutations/useUpdateFaq.mutation";
import { IFaq } from "../../../../Types/faq.interface";
import GetAllCourseName from "../../../../helper/GetAllCourseName";

interface IFaqModal {
  data: {
    opened: boolean;
    close: () => void;
    value?: IFaq;
    refetch: any;
  };
}

const FaqFormModel: React.FC<IFaqModal> = ({ data }) => {
  const { close, opened, value, refetch } = data;
  const isEditMode = !!value;
  const { mutateAsync, isPending } = useCreateFaq();
  const { mutateAsync: updateFaq, isPending: isUpdating } = useUpdateFaq();
  const courseNames = GetAllCourseName();
  const form = useForm<IFaq>({
    initialValues: formValue.course_faq_form.initialValues,
    validate: yupResolver(formValue.course_faq_form.validationSchema),
  });

  useEffect(() => {
    if (value) {
      form.setValues(value);
    } else {
      form.reset();
    }
  }, [value]);

  const handleAddDescription = () => {
    form.insertListItem("description", [""]);
  };

  const handleRemoveDescription = (index: number) => {
    form.removeListItem("description", index);
  };

  const handleAdminSubmit = async (e: IFaq) => {
    try {
      console.log("e", e);
      // e.description = descriptions;
      const res = isEditMode
        ? await updateFaq({ data: e, _id: e._id ?? "" })
        : await mutateAsync(e);

      if (res.statusCode === 200) {
        notifications.show({ message: res.message, color: "green" });
        form.reset();
        close();
        refetch();
      }
    } catch (error: any) {
      console.log("error", error);
      notifications.show({ message: error.message, color: "red" });
    }
  };

  return (
    <Modal
      opened={opened}
      onClose={close}
      title={isEditMode ? "Edit Faq" : "Add Faq"}
      closeOnClickOutside={false}
      size={"lg"}
    >
      <form
        onSubmit={form.onSubmit(handleAdminSubmit)}
        style={{ width: "100%" }}
      >
        <Box>
          <Select
            mb={12}
            required
            defaultValue={value ? value.courseId : courseNames[0]?.value}
            allowDeselect={false}
            label={"Select Course Name"}
            placeholder="Eg. Advanced Valuation and Financial Modelling"
            data={courseNames}
            {...form.getInputProps("courseId")}
          />
          <TextInput
            mb={12}
            required
            label={"Enter Heading"}
            placeholder={"Eg. Rohan Yadav"}
            {...form.getInputProps("heading")}
          />

          {form.values.description.map((desc, index) => (
            <Box key={index} mb={12}>
              <Textarea
                label={`Description ${index + 1}`}
                placeholder="Enter description"
                value={desc}
                {...form.getInputProps(`description.${index}`)}
                required
              />
              {index > 0 && (
                <Button
                  mt={8}
                  color="red"
                  onClick={() => handleRemoveDescription(index)}
                >
                  Remove Description
                </Button>
              )}
            </Box>
          ))}

          <Flex direction={"column"} gap={"md"}>
            <Button mt={12} onClick={handleAddDescription} fullWidth>
              Add New Description
            </Button>

            <FButton
              label={isEditMode ? "Edit Faq" : "Add Faq"}
              type="submit"
              width="100%"
              loading={isEditMode ? isUpdating : isPending}
            />
          </Flex>
        </Box>
      </form>
    </Modal>
  );
};
export default FaqFormModel;
