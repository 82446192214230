import { Link } from "react-router-dom";
import { TTableColumns } from "../../Types/table.interface";
export const joyResultcolumn: TTableColumns<IJoyresult>[] = [
  {
    key: "sno",
    label: "S. No.",
    minWidth:100
  },
  {
    key: "title",
    label: "Title",
    minWidth:250
  },
  {
    key: "username",
    label: "Name",
    minWidth:250
  },
  {
    key: "descripction",
    label: "descripction",
    minWidth:300
  },
  {
    key: "linkedinUrl",
    label: "Linkedin Url",
    minWidth:300,
    renderCell: (value) => <Link to={value.linkedinUrl} target="_blank"> {value.linkedinUrl} </Link>
  },
  {
    key: "linkedinProfile",
    label: "Linkedin Profile",
    minWidth:300,
    renderCell: (value) => <img src={value.linkedinProfile as string} alt="" width={200}/>
  },
  {
    key: "identity",
    label: "Identity",
    minWidth:200,
  },

];
