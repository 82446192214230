import * as Yup from "yup";
import { IoverallHome } from "../../Types/overallHomePage.interface";

const initialValues: IoverallHome = {
  enrolledStudents: "",
  totalLectureHours: "",
  averageRatings: "",
  linkedinSubscribers: "",
  youtubeSubscribers: "",
};

const validationSchema = Yup.object({
  enrolledStudents: Yup.string().required(),
  totalLectureHours: Yup.string().required(),
  averageRatings: Yup.string().required(),
  linkedinSubscribers: Yup.string().required(),
  youtubeSubscribers: Yup.string().required(),
});
export const overallHomePage_form = {
  initialValues,
  validationSchema,
};
