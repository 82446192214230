import { ActionIcon, Flex } from "@mantine/core";
import { IconEdit } from "@tabler/icons-react";
import React, { Fragment } from "react";
import { useDisclosure } from "@mantine/hooks";
import { IoverallHome } from "../../../../Types/overallHomePage.interface";
import LandingPageModel from "../model/LandingPageModel";

interface IActionButton {
  data: IoverallHome;
  refetch: any;
}

const ActionButton: React.FC<IActionButton> = ({ data, refetch }) => {
  const [editOpened, { open: openEdit, close: closeEdit }] =
    useDisclosure(false);

  return (
    <Fragment>
      <Flex justify={"center"} align={"center"} gap={20}>
        <ActionIcon variant="outline" onClick={openEdit}>
          <IconEdit style={{ width: "70%", height: "70%" }} stroke={1.5} />
        </ActionIcon>
      </Flex>
      <LandingPageModel
        data={{
          close: closeEdit,
          opened: editOpened,
          value: data,
          refetch: refetch,
        }}
      />
    </Fragment>
  );
};

export default ActionButton;
