import { useMutation } from "@tanstack/react-query";

import apiurls from "../apiurls";
import { request } from "../../services/axios.service";
import { IAlumni } from "../../Types/alumni.interface";

const createUpdateAlumni = async (alumni: IAlumni) => {
  const res: TServerResponse = await request({
    url: apiurls.alumniUrls.CREATE_UPDATE,
    method: "POST",
    data: alumni,
  });
  return res;
};

export default () => {
  return useMutation({
    mutationFn: createUpdateAlumni,
  });
};
