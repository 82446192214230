import { useMutation } from "@tanstack/react-query";
import { request } from "../../../services/axios.service";
import apiurls from "../../apiurls";

const deleteJoyResult = async ({_id}:{_id:string}) => {
  const res: TServerResponse = await request({
    url: apiurls.joyResultApi.JOY_RESULT_DELETE,
    method: "POST",
    data:{
        id:_id
    }
  });
  return res;
};

export const useDeleteJoyResult = () => {
  return useMutation({
    mutationFn: deleteJoyResult,
  });
};
