import React from "react";
import GetAllCourseName from "../../helper/GetAllCourseName";

const Dashboard = () => {
  const courseNames = GetAllCourseName();
  console.log("course", courseNames);

  return <div>Dashboard</div>;
};

export default Dashboard;
