import { Box, Button, Flex, Select, Text, TextInput } from "@mantine/core";
import React, { Fragment, useMemo, useState } from "react";
import PaginatedTable from "../../components/paginated-table/PaginatedTable";
import { TTableColumns } from "../../Types/table.interface";
import { CONSTANTS } from "../../constants/index.constant";
import { useDebouncedState, useDisclosure } from "@mantine/hooks";
import { TABLE_COLUMN } from "../../constants/tables";
import { IconPlus } from "@tabler/icons-react";
import CourseReviewsFormModel from "./components/model/CourseReviewsFormModel";
import CourseReviewsActionButton from "./components/action-button/CourseReviewsActionButton";
import { useGetAllCourseReview } from "../../hooks/course-reviews/query/getAllCourseReview.query";
import GetAllCourseName from "../../helper/GetAllCourseName";

const CourseReviews = () => {
  const [opened, { open, close }] = useDisclosure(false);
  const [page, setPage] = useState(1);
  const [search, setSearch] = useDebouncedState("", 300);
  const courseNames = GetAllCourseName();
  const [selectedCourse, setSelectedCourse] = useState<string>(
    courseNames && courseNames[0]?.value
  );
  const { data, isLoading, refetch } = useGetAllCourseReview({
    page,
    search,
    courseId: selectedCourse as string,
  });
  const handleCourseChange = (value: any) => {
    setSelectedCourse(value);
  };

  const combinedColumns = useMemo(() => {
    const newColumns = {
      key: "Action",
      label: "Action",
      minWidth: 300,
      renderCell: (value: any) => (
        <CourseReviewsActionButton data={value} refetch={refetch} />
      ),
    };

    return [
      ...(TABLE_COLUMN.courseReviewcolumn as TTableColumns<unknown>[]),
      newColumns,
    ];
  }, [refetch]);

  const { data: tableData, total } = useMemo(() => {
    if (!isLoading && data?.data) {
      const serialized =
        (data?.data.data as []).map((item, index) => ({
          ...(item as unknown as object),
          sno: ((page ?? 1) - 1) * CONSTANTS.PAGE_LIMIT + (index + 1),
        })) ?? [];
      return {
        data: serialized,
        total: data.data.totalDocuments,
      };
    }
    return { data: [], total: 0 };
  }, [data, isLoading, page]);

  return (
    <Fragment>
      <Box pr={24}>
        <Flex justify={"space-between"} align={"center"}>
          <Text fw={600} fz={"h3"} my={24}>
            Course Review and Feedback
          </Text>
          <Button leftSection={<IconPlus />} onClick={open}>
            Add Review & Feedback
          </Button>
        </Flex>
        <Flex justify={"space-between"} align={"center"} gap={20}>
          <Select
            mb={12}
            label={"Select Course Name"}
            placeholder="Eg. Advanced Valuation and Financial Modelling"
            data={courseNames}
            value={selectedCourse} // Controlled value
            onChange={handleCourseChange}
          />
          <TextInput
            onChange={(value) => setSearch(value.target.value)}
            placeholder="Search Review & Feedback"
            styles={{ root: { width: 218 } }}
          />
        </Flex>
        <Box>
          <PaginatedTable
            columns={combinedColumns}
            data={tableData}
            isLoading={isLoading}
            keyExtractor={(e) => {
              const key = e as { _id: string };
              return key._id;
            }}
            paginationProps={{
              page,
              setPage,
              totalDocuments: Number(total),
              pageLimit: CONSTANTS.PAGE_LIMIT,
            }}
          />
        </Box>
      </Box>

      <CourseReviewsFormModel
        data={{ close: close, opened: opened, refetch: refetch }}
      />
    </Fragment>
  );
};

export default CourseReviews;
