import React, { useEffect, useState } from "react";
import { useForm, yupResolver } from "@mantine/form";
import {
  Box,
  Button,
  Divider,
  Flex,
  Group,
  Text,
  Textarea,
  TextInput,
  Select,
} from "@mantine/core";
import { useNavigate, useParams } from "react-router-dom";
import { notifications } from "@mantine/notifications";
import { useFetchBlogById } from "../../hooks/blogs/mutation/useFetchBlogById.mutation";
import { useCreateBlog } from "../../hooks/blogs/mutation/useCreateBlog.mutation";
import { useUpdateBlog } from "../../hooks/blogs/mutation/useUpdateBlog.mutation";
import ImageCropInput2 from "../../container/ImageCropPicker/ImageCropInput2";
import RichTextEditorWrapper from "../../components/RichTextEditor/RichTextEditorWrapper";
import { IBlog } from "../../Types/table.interface";
import { useFetchCategories } from "../../hooks/categories/query/useFetchCategories.query";
import { blogvalidationSchema } from "../../validation/blog.validation";

const AddBlogForm: React.FC = () => {
  const { id } = useParams();
  const isEditing = Boolean(id);

  const [loading, setLoading] = useState(false);
  const [categories, setCategories] = useState<{ _id: string; name: string }[]>(
    []
  );
  const [categoryLoading, setCategoryLoading] = useState(true); // Loading state for categories
  const [categoryError, setCategoryError] = useState<string | null>(null); // Error handling for category fetch

  const { mutateAsync: fetchBlog } = useFetchBlogById();
  const { mutateAsync: createBlog } = useCreateBlog();
  const { mutateAsync: updateBlog } = useUpdateBlog();
  const { data: categoriesData } = useFetchCategories(); // Hook to fetch categories
  const navigate = useNavigate();

  const initialValues: IBlog = {
    category: "",
    cardData: {
      image: null,
      name: "",
      minutes: "",
      title: "",
      description: "",
    },
    pageData: [
      {
        img: null,
        title: "",
        summary: "",
        description: "",
      },
    ],
  };

  const form = useForm<IBlog>({
    initialValues,
    validate: yupResolver(blogvalidationSchema),
  });

  // Fetch categories and handle loading, error
  useEffect(() => {
    const fetchCategories = async () => {
      try {
        if (categoriesData && categoriesData.status === "success") {
          // Log the categories data to ensure it's being returned as expected
          console.log("Fetched categories data:", categoriesData.data);
          setCategories(categoriesData.data);
          setCategoryError(null); // Reset any previous errors
        } else {
          // If the categoriesData doesn't have the expected format, show an error
          setCategoryError("Failed to load categories.");
        }
      } catch (error) {
        // Catch any other errors and log them
        console.error("Error fetching categories:", error);
        setCategoryError("Error fetching categories.");
      } finally {
        setCategoryLoading(false); // Stop loading
      }
    };

    // Only fetch if data is not already available (or if necessary)
    if (categoriesData && !categories.length) {
      fetchCategories();
    }
  }, [categoriesData]); // Trigger effect whenever categoriesData changes

  useEffect(() => {
    if (isEditing) {
      setLoading(true);
      fetchBlog({ id: id! })
        .then((res) => {
          const blogData = res.data;
          form.setValues({
            category: blogData.category._id || "",
            cardData: {
              image: blogData.cardData?.image || null,
              name: blogData.cardData?.name || "",
              minutes: blogData.cardData?.minutes || "",
              title: blogData.cardData?.title || "",
              description: blogData.cardData?.description || "",
            },
            pageData: blogData.pageData.map((page: any) => ({
              img: page.img || null,
              title: page.title || "",
              summary: page.summary || "",
              description: page.description || "",
            })),
          });
        })
        .catch(() => {
          notifications.show({
            title: "Error",
            message: "Failed to fetch blog data.",
            color: "red",
          });
        })
        .finally(() => setLoading(false));
    }
  }, [isEditing, fetchBlog, id]);

  const onSubmit = async (values: IBlog) => {
    try {
      if (isEditing) {
        const update = await updateBlog({ ...values, blogId: id });
        if (update.status === "success") {
          notifications.show({
            title: "Blog Updated",
            message: "Your blog has been successfully updated.",
            color: "green",
          });
          form.reset();
          navigate("/blogs");
        }
      } else {
        const res = await createBlog({ ...values, _id: id });
        if (res.status === "success") {
          notifications.show({
            title: "Blog Created",
            message: "Your blog has been successfully created.",
            color: "green",
          });
          form.reset();
          navigate("/blogs");
        }
      }
    } catch (error: any) {
      notifications.show({
        title: "Error",
        message:
          error.response?.data?.message || "An unexpected error occurred.",
        color: "red",
      });
    }
  };

  return (
    <Box style={{ maxWidth: 800, margin: "2rem auto" }}>
      <Text size="xl">{isEditing ? "Edit Blog" : "Add Blog"}</Text>
      {loading ? (
        <Text>Loading...</Text>
      ) : (
        <form
          style={{ display: "flex", flexDirection: "column", gap: "1rem" }}
          onSubmit={form.onSubmit(onSubmit)}
        >
          {categoryLoading ? (
            <Text>Loading categories...</Text>
          ) : categoryError ? (
            <Text color="red">{categoryError}</Text>
          ) : (
            <Select
              label="Category"
              data={categories.map((category) => ({
                value: category._id,
                label: category.name,
              }))}
              placeholder="Select category"
              {...form.getInputProps("category")}
              withAsterisk
            />
          )}
          <Flex gap="md" align="flex-start">
            <ImageCropInput2
              form={form}
              name="cardData.image"
              label="Card Image"
              accept="image/*"
              aspectRatio={293 / 210}
            />
            <TextInput
              label="Card Title"
              {...form.getInputProps("cardData.title")}
              placeholder="Enter card title"
              withAsterisk
            />
          </Flex>
          <Textarea
            label="Card Description"
            {...form.getInputProps("cardData.description")}
            placeholder="Enter card description"
            autosize
            minRows={3}
            withAsterisk
          />
          <Flex gap={"lg"}>
            <TextInput
              label="Author Name"
              placeholder="Enter Author name"
              {...form.getInputProps("cardData.name")}
              withAsterisk
            />
            <TextInput
              label="Average Read Time (in Minutes)"
              placeholder="Enter the average read time"
              {...form.getInputProps("cardData.minutes")}
              withAsterisk
            />
          </Flex>
          {form.values.pageData.map((page, index) => (
            <Box
              key={index}
              mt="lg"
              style={{ display: "flex", flexDirection: "column", gap: "1rem" }}
            >
              <Divider label={`Page ${index + 1}`} />
              <ImageCropInput2
                form={form}
                name={`pageData.${index}.img`}
                label="Page Image"
                accept="image/*"
                aspectRatio={1232 / 396}
              />
              <TextInput
                label="Page Title"
                {...form.getInputProps(`pageData.${index}.title`)}
                placeholder="Enter page title"
                withAsterisk
              />
              <Textarea
                label="Page Summary"
                {...form.getInputProps(`pageData.${index}.summary`)}
                placeholder="Enter page summary"
                autosize
                minRows={3}
                withAsterisk
              />
              <Text>
                Page Description <span style={{ color: "red" }}>*</span>
              </Text>
              <RichTextEditorWrapper
                value={form.values.pageData[index].description}
                onChange={(value) =>
                  form.setFieldValue(`pageData.${index}.description`, value)
                }
              />
            </Box>
          ))}
          <Group mt="xl">
            <Button type="submit">
              {isEditing ? "Update Blog" : "Create Blog"}
            </Button>
          </Group>
        </form>
      )}
    </Box>
  );
};

export default AddBlogForm;
