import { useMutation } from "@tanstack/react-query";
import { request } from "../../../services/axios.service";
import apiurls from "../../apiurls";
import { ICourseReview } from "../../../Types/courseReview.interface";

const create = async (data: ICourseReview) => {
  const res: TServerResponse = await request({
    url: apiurls.courseReviewApis.UPDATE,
    method: "POST",
    data,
  });
  return res;
};

export const useUpdateCourseReviews = () => {
  return useMutation({
    mutationFn: create,
  });
};
