import { Box, Button } from "@mantine/core";
import { IconBrandGoogle } from "@tabler/icons-react";
import React from "react";

const GoogleTokenCreate:React.FC = () => {
  const handleGoogleLogin = async () => {
    window.location.href = `${process.env.REACT_APP_BASE_URL}/auth/google`;
  };

  return (
    <Box mt={40}>
      <Button leftSection={<IconBrandGoogle />} onClick={handleGoogleLogin}>
        Google login for Contacts
      </Button>
    </Box>
  );
};

export default GoogleTokenCreate;
