import { Link } from "react-router-dom";
import { TTableColumns } from "../../Types/table.interface";
export const youtubePlayListcolumn: TTableColumns<IYoutubePlaylist>[] = [
  {
    key: "sno",
    label: "S. No.",
    minWidth:100
  },
  {
    key: "title",
    label: "Button Title",
    minWidth:250
  },
  {
    key: "heading",
    label: "Heading",
    minWidth:250
  },
  {
    key: "descripction",
    label: "Descripction",
    minWidth:300
  },
  {
    key: "youtubeLink",
    label: "youtube Link",
    minWidth:300,
    renderCell: (value) => <Link to={value.youtubeLink} target="_blank"> {value.youtubeLink} </Link>
  },
  {
    key: "banner",
    label: "Banner",
    minWidth:300,
    renderCell: (value) => <img src={value.banner as string} alt="" width={200}/>
  },

];
