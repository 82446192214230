import { useQuery } from "@tanstack/react-query";
import { request } from "../../../services/axios.service";
import apiurls from "../../apiurls";
type TProps = {
  page: number;
  limit: number;
};
const get = async (data: TProps) => {
  const res: TServerResponse = await request({
    url: apiurls.joyResultApi.JOY_RESULT_GET_ALL,
    method: "GET",
    params: data,
  });
  return res;
};

export const useGetAllJoyResult = (data: TProps) => {
  return useQuery({
    queryKey: ["joy-result", data],
    queryFn: () => get(data),
  });
};
