import { Box, FileInput, Image, Modal, Textarea, TextInput } from "@mantine/core";
import React, { useEffect } from "react";
import FButton from "../../../../components/ui/Button/FButton";
import { useForm, yupResolver } from "@mantine/form";
import { useCreateYouTubePlaylist } from "../../../../hooks/youtube-playlist/mutation/createYoutubePlaylist.mutation";
import { IconPhotoPlus } from "@tabler/icons-react";
import { formValue } from "../../../../form-values";
import { notifications } from "@mantine/notifications";
import { useUpdateYouTubePlaylist } from "../../../../hooks/youtube-playlist/mutation/updateYoutubePlayList.mutation";

interface IYoutubePlayListAddModel {
  data: {
    opened: boolean;
    close: () => void;
    value?: IYoutubePlaylist;
    refetch: any;
  };
}

const YoutubePlayListAddModel: React.FC<IYoutubePlayListAddModel> = ({
  data,
}) => {
  const { close, opened, value, refetch } = data;
  const isEditMode = !!value;
  const { mutateAsync, isPending } = useCreateYouTubePlaylist();
  const { mutateAsync: updatePlaylist, isPending: isUpdating } =
    useUpdateYouTubePlaylist();
  const form = useForm<IYoutubePlaylist>({
    initialValues: formValue.youtubePlaylist_form.initialValues,
    validate: yupResolver(formValue.youtubePlaylist_form.validationSchema),
  });

  useEffect(() => {
    if (value) {
      form.setValues({...value,banner:value.banner}); // Set form values for editing
    }
  }, [value]);

  const handleAdminSubmit = async (e: IYoutubePlaylist) => {
    try {
      const res = isEditMode
        ? await updatePlaylist(e) // Update if in edit mode
        : await mutateAsync(e);
      if (res.statusCode === 200) {
        notifications.show({ message: res.message, color: "green" });
        form.reset();
        close();
        refetch();
      }
    } catch (error: any) {
      notifications.show({ message: error.message, color: "red" });
    }
  };

  return (
    <Modal
      opened={opened}
      onClose={close}
      title={isEditMode ? "Edit YouTube Playlist" : "Add YouTube Playlist"}
      closeOnClickOutside={false}
      size={"lg"}
    >
      <form
        onSubmit={form.onSubmit(handleAdminSubmit)}
        style={{ width: "100%" }}
      >
        <Box>
          <TextInput
            mb={12}
            required
            label={"Enter Button Title"}
            placeholder={"Enter button title"}
            {...form.getInputProps("title")}
          />
          <TextInput
            required
            mb={12}
            label={"Enter Heading Text"}
            placeholder={"Enter heading text"}
            {...form.getInputProps("heading")}
          />
          <TextInput
            required
            mb={12}
            label={"Enter Youtube Link"}
            placeholder={"Enter youtubeLink"}
            {...form.getInputProps("youtubeLink")}
          />
          <Textarea
            required
            mb={12}
            label={"Enter Descripction Text"}
            placeholder={"Enter descripction text"}
            rows={6}
            {...form.getInputProps("descripction")}
          />
          <FileInput
            required
            mb={12}
            leftSection={<IconPhotoPlus />}
            label={"Upload banner"}
            placeholder={"Upload banner"}
            leftSectionPointerEvents={"none"}
            {...form.getInputProps("banner")}
          />
          <Image src={value?.banner} mb={20} mt={20}/>
          <FButton
            label={isEditMode ? "Edit PlayList" : "Add Playlist"}
            type="submit"
            width="100%"
            loading={isEditMode ? isUpdating : isPending}
          />
        </Box>
      </form>
    </Modal>
  );
};

export default YoutubePlayListAddModel;
