import { useQuery } from "@tanstack/react-query";
import { request } from "../../../services/axios.service";
import apiurls from "../../apiurls";
type TProps = {
  page: number;
  limit: number;
};
const get = async (data: TProps) => {
  const res: TServerResponse = await request({
    url: apiurls.YOUTUBE_PLAYLIST_GET_ALL,
    method: "GET",
    params: data,
  });
  return res;
};

export const useGetYoutubePlaylist = (data: TProps) => {
  return useQuery({
    queryKey: ["youtube-playlist", data],
    queryFn: () => get(data),
  });
};
