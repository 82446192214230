import { useQuery } from "@tanstack/react-query";
import { request } from "../../../services/axios.service";
import apiurls from "../../apiurls";

const get = async (data: {
  page: number;
  search: string;
  courseId: string;
}) => {
  const res: TServerResponse = await request({
    url: apiurls.courseReviewApis.GET_ALL,
    method: "GET",
    params: {
      page: data.page,
      search: data.search,
      courseId: data.courseId,
    },
  });
  return res;
};

export const useGetAllCourseReview = (data: {
  page: number;
  search: string;
  courseId: string;
}) => {
  return useQuery({
    queryKey: ["get-course-reviews", data.page, data.search, data.courseId],
    queryFn: () => get(data),
  });
};
