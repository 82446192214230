import { useMutation, useQueryClient } from "@tanstack/react-query";
import apiurls from "../../apiurls";
import { request } from "../../../services/axios.service";
import { IBlog } from "../../../Types/table.interface";

const createBlog = async (data: IBlog) => {
  const res: TServerResponse = await request({
    headers: { "Content-Type": "multipart/form-data" },
    url: apiurls.CREATE_BLOG,
    method: "POST",
    data,
  });
  return res;
};

export const useCreateBlog = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: createBlog,
    onSuccess: () => {
      // Refetch the allBlog query
      queryClient.invalidateQueries({ queryKey: ["blogs"] }); // Replace "allBlog" with your actual query key
    },
  });
};
