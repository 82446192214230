import React from "react";
interface Iprops {
  value: any;
}
const CourseImage = ({ value }: Iprops) => {
  return (
    <img src={value.bannerImage} width={50} height={50} alt="card_image" />
  );
};

export default CourseImage;
