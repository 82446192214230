import React, { useEffect } from "react";
import { useForm, yupResolver } from "@mantine/form";
import {
  Button,
  Flex,
  Loader,
  NumberInput,
  Textarea,
  TextInput,
} from "@mantine/core";
import { modals } from "@mantine/modals";
import { IAlumni } from "../../../Types/alumni.interface";
import { notifications } from "@mantine/notifications";
import { queryClient } from "../../../App";
import useCreateUpdateAlumni from "../../../hooks/alumni/useCreateUpdateAlumni";
import useGetAlumniById from "../../../hooks/alumni/useGetAlumniById";
import { formValue } from "../../../form-values";

interface IProps {
  isCreate: boolean;
  id?: string;
}


const AlumniModal: React.FC<IProps> = ({ isCreate, id }) => {
  const { mutateAsync, isPending } = useCreateUpdateAlumni();

  const { data, isLoading } = useGetAlumniById(id ?? "");

  useEffect(() => {
    if (!isLoading && data?.data) {
      form.setValues(data.data.alumni);
    }
  }, [isLoading, data]);

  const form = useForm<IAlumni>({
    initialValues: formValue.alumnis_form.initialValues,
    validate: yupResolver(formValue.alumnis_form.validationSchema),
  });

  const handleSubmit = async (data: IAlumni) => {
    const response = await mutateAsync(data);

    if (response.status === "error") {
      return notifications.show({
        message: response.message,
        color: "red",
      });
    }
    queryClient.invalidateQueries({ queryKey: ["alumni-get-all"] });
    modals.closeAll();
    return notifications.show({
      message: response.message,
      color: "green",
    });
  };

  if (id && isLoading) {
    return (
      <Flex justify={"center"}>
        <Loader />
      </Flex>
    );
  }

  return (
    <form onSubmit={form.onSubmit((e) => handleSubmit(e))}>
      <Flex m={"lg"} gap={"md"} direction={"column"}>
        <TextInput
          label="Name"
          placeholder="Name"
          {...form.getInputProps("name")}
        />
        <TextInput
          label="Position"
          placeholder="Position"
          {...form.getInputProps("position")}
        />

        <NumberInput
          min={0}
          max={5}
          label="Rating"
          placeholder="Enter Rating"
          {...form.getInputProps("rating")}
        />
        <TextInput
          label="Image URL"
          placeholder="Enter Image URL"
          {...form.getInputProps("imageUrl")}
        />
        <TextInput
          label="Enter Linkedin Profile (Image) Url"
          placeholder="Enter Linkedin Profile (Image) Url"
          {...form.getInputProps("linkedinUrl")}
        />
        <Textarea
          label="Description"
          placeholder="Description"
          rows={8}
          {...form.getInputProps("description")}
        />

        <Flex
          align={"center"}
          justify={"center"}
          gap={40}
          miw={!isCreate ? 420 : ""}
        >
          <Button
            fullWidth
            disabled={isPending}
            onClick={() => modals.closeAll()}
            color="red"
          >
            {"Cancel"}
          </Button>
          <Button fullWidth loading={isPending} type="submit">
            {isCreate ? "Create" : "Submit"}
          </Button>
        </Flex>
      </Flex>
    </form>
  );
};

export default AlumniModal;
