import React from "react";
import { TTableColumns } from "../../Types/table.interface";
import CommonActionBar from "../../components/common/ActionBar/CommonActionBar";
import moment from "moment";
import { ICourses } from "../../Types/courses.interface";
import CourseImage from "../../components/CourseImage";

export const generateCourseTable = (
  deleteCourse: any,
  navigate: (path: string) => void
): TTableColumns<ICourses>[] => [
  {
    key: "sno",
    label: "S No",
  },
  {
    key: "name",
    label: "Name",
    renderCell: (value) => value.name,
  },
  {
    key: "bannerImage",
    label: "Banner Image",
    renderCell: (value) => <CourseImage value={value} />,
  },
  {
    key: "date",
    label: "Date",
    renderCell: (value) =>
      moment(value.createdAt).format("DD-MMM-YYYY - hh:mm:ss A"),
  },
  {
    key: "updated",
    label: "Updated At",
    renderCell: (value) =>
      moment(value.updatedAt).format("DD-MMM-YYYY - hh:mm:ss A"),
  },
  {
    key: "action",
    label: "Actions",
    renderCell: (value) => (
      <CommonActionBar
        value={value}
        name="Course"
        handleDelete={() => deleteCourse(value._id ?? "")}
        handleEdit={() => navigate(`/courses/edit-course/${value._id}`)}
      />
    ),
  },
];
