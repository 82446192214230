import * as Yup from "yup";
export const blogvalidationSchema = Yup.object().shape({
  category: Yup.string().required("Category is required"),
  cardData: Yup.object().shape({
    image: Yup.mixed().required("Card image is required"),
    title: Yup.string().required("Card title is required"),
    description: Yup.string().required("Card description is required"),
    name: Yup.string().required("Author name is required"),
    minutes: Yup.number()
      .required("Read time is required")
      .min(1, "Read time must be at least 1 minute"),
  }),
  pageData: Yup.array()
    .of(
      Yup.object().shape({
        img: Yup.mixed().required("Page image is required"),
        title: Yup.string().required("Page title is required"),
        summary: Yup.string().required("Page summary is required"),
        description: Yup.string().required("Page description is required"),
      })
    )
    .min(1, "At least one page is required"),
});
