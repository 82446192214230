import {
  NumberInput,
  NumberInputProps,
  TextInput,
  TextInputProps,
} from "@mantine/core";
import React from "react";
import classes from "./index.module.css";
interface ICustomTextInput {
  variant?: "number";
  label: string;
  placeholder: string;
  value?: string;
  padding?: string;
  fontSize?: string;
  formHandler?: TextInputProps | NumberInputProps;
  disabled?: boolean;
  onKeyDown?: React.KeyboardEventHandler<HTMLInputElement>;
}
const Input: React.FC<ICustomTextInput> = ({
  label,
  placeholder,
  formHandler,
  disabled,
  onKeyDown,
  padding,
  fontSize,
  value,
  variant,
}) => {
  if (variant === "number") {
    return (
      <NumberInput
        hideControls
        disabled={disabled}
        label={label}
        placeholder={placeholder}
        classNames={{ input: classes.input, label: classes.label }}
        styles={{
          root: {
            width: "100%",
          },
        }}
        min={0}
        max={5}
        {...(formHandler as NumberInputProps)}
      />
    );
  }
  return (
    <TextInput
      label={label}
      value={value}
      placeholder={placeholder}
      disabled={disabled}
      onKeyDown={onKeyDown}
      {...(formHandler as TextInputProps)}
      w={"100%"}
      classNames={{ input: classes.input }}
      styles={{
        input: {
          padding: padding,
          fontSize: fontSize,
        },
        label: {
          fontSize: fontSize,
        },
      }}
    />
  );
};

export default Input;
