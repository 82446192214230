import { Box, Modal, Select, Textarea, TextInput } from "@mantine/core";
import React, { useEffect } from "react";
import FButton from "../../../../components/ui/Button/FButton";
import { useForm, yupResolver } from "@mantine/form";
import { formValue } from "../../../../form-values";
import { notifications } from "@mantine/notifications";
import { ICourseReview } from "../../../../Types/courseReview.interface";
import { useCreateCourseReviews } from "../../../../hooks/course-reviews/mutation/createCourseReviews.mutation";
import { useUpdateCourseReviews } from "../../../../hooks/course-reviews/mutation/updateCourseReviews.mutation";
// import { COURSE_NAME } from "../../../../constants/index.constant";
import GetAllCourseName from "../../../../helper/GetAllCourseName";

interface ICourseReviewsFormModel {
  data: {
    opened: boolean;
    close: () => void;
    value?: ICourseReview;
    refetch: any;
  };
}

const CourseReviewsFormModel: React.FC<ICourseReviewsFormModel> = ({
  data,
}) => {
  const { close, opened, value, refetch } = data;
  const isEditMode = !!value;
  const { mutateAsync, isPending } = useCreateCourseReviews();
  const { mutateAsync: updateCourseReview, isPending: isUpdating } =
    useUpdateCourseReviews();
  const courseNames = GetAllCourseName();
  const form = useForm<ICourseReview>({
    initialValues: formValue.course_review_form.initialValues,
    validate: yupResolver(formValue.course_review_form.validationSchema),
  });

  useEffect(() => {
    if (value) {
      form.setValues({ ...value }); // Set form values for editing
    }
  }, [value]);

  const handleAdminSubmit = async (e: ICourseReview) => {
    try {
      const res = isEditMode
        ? await updateCourseReview(e) // Update if in edit mode
        : await mutateAsync(e);
      if (res.statusCode === 200) {
        notifications.show({ message: res.message, color: "green" });
        form.reset();
        close();
        refetch();
      }
    } catch (error: any) {
      notifications.show({ message: error.message, color: "red" });
    }
  };

  return (
    <Modal
      opened={opened}
      onClose={close}
      title={isEditMode ? "Edit Review & Feedback" : "Add Review & Feedback"}
      closeOnClickOutside={false}
      size={"lg"}
    >
      <form
        onSubmit={form.onSubmit(handleAdminSubmit)}
        style={{ width: "100%" }}
      >
        <Box>
          <Select
            mb={12}
            required
            defaultValue={value ? value.courseId : courseNames[0]?.value}
            allowDeselect={false}
            label={"Select Course Name"}
            placeholder="Eg. Advanced Valuation and Financial Modelling"
            data={courseNames}
            {...form.getInputProps("courseId")}
          />
          <TextInput
            mb={12}
            required
            label={"Enter Name"}
            placeholder={"Eg. Rohan Yadav"}
            {...form.getInputProps("name")}
          />
          <TextInput
            required
            mb={12}
            label={"Enter Designation"}
            placeholder={"Eg. Web Depolover"}
            {...form.getInputProps("designation")}
          />
          <TextInput
            // required
            mb={12}
            label={"Enter Linkedin Url"}
            placeholder={
              "Eg. https://www.linkedin.com/in/rajveer-jatav-485045215/"
            }
            {...form.getInputProps("linkedinUrl")}
          />
          <TextInput
            // required
            mb={12}
            label={"Enter Linkedin Profile Url"}
            placeholder={
              "Eg. https://media.licdn.com/dms/image/v2/C4E03AQGFCcUjHzHUGQ/profile-displayphoto-shrink_800_800/profile-displayphoto-shrink_800_800/0/1623765359492?e=1737590400&v=beta&t=5eQyanxL_5J-KzmRwDqX4pDNffCX1DrHRdlayOKMnZs"
            }
            {...form.getInputProps("imageUrl")}
          />
          <Textarea
            required
            mb={12}
            label={"Enter Descripction"}
            placeholder={
              "Eg. First of all, I would like to express my heartfelt thanks for all the knowledge provided to us through this cohort."
            }
            rows={6}
            {...form.getInputProps("description")}
          />
          <FButton
            label={isEditMode ? "Edit Review" : "Add Review"}
            type="submit"
            width="100%"
            loading={isEditMode ? isUpdating : isPending}
          />
        </Box>
      </form>
    </Modal>
  );
};

export default CourseReviewsFormModel;
