import { useMutation } from "@tanstack/react-query";
import apiurls from "../../apiurls";
import { request } from "../../../services/axios.service";

type TProps = {
  id: string;
};
const fetchBlog = async (data: TProps) => {
  const res: TServerResponse = await request({
    url: apiurls.GET_SINGLE_BLOG,
    method: "POST",
    data,
  });
  return res;
};

export const useFetchBlogById = () => {
  return useMutation({
    mutationFn: fetchBlog,
  });
};
