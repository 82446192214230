import { Box, Flex, Select } from "@mantine/core";
import React from "react";

import CustomPagination from "./CustomPagination";
import classes from "./index.module.css";
import { TLimit, TPaging } from "../../../Types/table.interface";
interface IProps {
  paginationProps: TPaging;
  limitProps?: TLimit;
}
const PaginationComponent: React.FC<IProps> = ({
  limitProps,
  paginationProps,
}) => {
  return (
    <Box className={classes.bottom}>
      {limitProps?.value && (
        <Flex align={"center"} gap={6}>
          <Select
            value={limitProps.value}
            allowDeselect={false}
            withCheckIcon={false}
            onChange={(e) => {
              if (limitProps?.setValue) {
                paginationProps.setPage(1);
                limitProps.setValue(e ?? "10");
              }
            }}
            data={["10", "25", "50", "100", "200"]}
            classNames={{ input: classes.selectInput }}
          />{" "}
          <p>rows per page</p>
        </Flex>
      )}
      <CustomPagination {...paginationProps} />
    </Box>
  );
};

export default PaginationComponent;
