export const ROUTES = {
  ADMIN_LOGIN: "/login",
  ADMINDASHBOARD: "/",
  STUDENTS: "/students",
  NEWSLETTER: "/newsletters",
  CONTACT_US: "/contact-us",
  ALUMNIS: "/alumnis",
  BLOGS: "/blogs",
  ADD_BLOG: "add-blog",
  EDIT_BLOG: "edit-blog/:id",
  COURSE_REVIEWS: "course-reviews",
  LEARNING_MODULE: "learning-module",
  FAQ: "faq",
  COURSES: "/courses",
  ADD_COURSE: "add-course",
  EDIT_COURSE: "edit-course/:id",
  LANDING_PAGE: "home-page",
  YOUTUBE_PLAYLIST: "youtube-playlist",
  JOY_RESULT: "joy-result",
  GOOGLE_TOKEN_CREATE: "/google-token-create",
  REGISTERED_STUDENTS: "/transactions",
};
