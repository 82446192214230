import {
  IconAddressBook,
  IconBrandFeedly,
  IconLayoutDashboard,
  IconUser,
  IconNews,
  IconWorld,
  IconBrandYoutube,
  IconBooks,
  IconBinoculars,
  IconCertificate,
  IconMessageFilled,
  IconHome,
  IconReportAnalytics,
  IconBrandGoogle,
  IconTransactionRupee,
  IconSchool,
} from "@tabler/icons-react";
import { MainLinkProps } from "./MainLinks";
import { ROUTES } from "../enum/routes";

//   const iconSize = 18;

export const naves: MainLinkProps[] = [
  {
    to: ROUTES.ADMINDASHBOARD,
    label: "Dashboard",
    icon: <IconLayoutDashboard />,
    color: "primary",
  },
  {
    to: ROUTES.STUDENTS,
    label: "Students",
    icon: <IconUser />,
    color: "primary",
  },
  {
    to: ROUTES.NEWSLETTER,
    label: "Newsletters",
    icon: <IconBrandFeedly />,
    color: "primary",
  },
  {
    to: ROUTES.CONTACT_US,
    label: "Contact Us Forms",
    icon: <IconAddressBook />,
    color: "primary",
  },
  {
    to: ROUTES.REGISTERED_STUDENTS,
    label: "Course Buy",
    icon: <IconTransactionRupee />,
    color: "primary",
  },
  {
    to: "/home",
    label: "Home Page (Website)",
    icon: <IconWorld />,
    color: "primary",
    children: [
      {
        to: `/${ROUTES.LANDING_PAGE}`,
        label: "Home Hero Section",
        icon: <IconHome />,
        color: "primary",
      },
      {
        to: `/${ROUTES.YOUTUBE_PLAYLIST}`,
        label: "Youtube Playlist",
        icon: <IconBrandYoutube />,
        color: "primary",
      },
      {
        to: `/${ROUTES.JOY_RESULT}`,
        label: "Testimonials (Joy Result)",
        icon: <IconReportAnalytics />,
        color: "primary",
      },
    ],
  },
  {
    to: `${ROUTES.COURSES}`,
    label: "Courses",
    icon: <IconCertificate />,
    color: "primary",
  },
  {
    to: "/course",
    label: "Course Page (Website)",
    icon: <IconSchool />,
    color: "primary",
    children: [
      {
        to: `/${ROUTES.COURSE_REVIEWS}`,
        label: "Course Reviews",
        icon: <IconBinoculars />,
        color: "primary",
      },
      {
        to: `/${ROUTES.LEARNING_MODULE}`,
        label: "Learning Module",
        icon: <IconBooks />,
        color: "primary",
      },
      {
        to: `/${ROUTES.FAQ}`,
        label: "FAQs",
        icon: <IconMessageFilled />,
        color: "primary",
      },
    ],
  },
  {
    to: ROUTES.ALUMNIS,
    label: "Alumni",
    icon: <IconUser />,
    color: "primary",
  },
  {
    to: ROUTES.BLOGS,
    label: "Blogs",
    icon: <IconNews />,
    color: "primary",
  },

  {
    to: ROUTES.GOOGLE_TOKEN_CREATE,
    label: "Google Token Create",
    icon: <IconBrandGoogle />,
    color: "primary",
  },
];
