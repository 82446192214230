import { Box, Button, Flex, Modal } from "@mantine/core";
import React from "react";
import clasees from "./index.module.css";

interface IConfirmationModel {
 data:{
    opened: boolean;
    close: () => void;
    confirmOnclick: () => void;
    title?:string,
    loading?:boolean
 }
}

const ConfirmationModel: React.FC<IConfirmationModel> = ({data}) => {
    const {close,confirmOnclick,opened,title,loading} = data
  return (
    <Modal
      opened={opened}
      onClose={close}
    //   title="Add Youtube Playlist"
      closeOnClickOutside={false}
      centered
      withCloseButton={false}
    >
      <Box>
        <h4 className={clasees.title}> {title ?? "Are you sure?"} </h4>
        <p className={clasees.descripction}>
            Are you sure you want to delete this entry. <br/> This action cannot be undone
        </p>
        <Flex justify={"space-between"} gap={10}>
        <Button onClick={close} classNames={{root:clasees.btn}} > No </Button>
        <Button onClick={confirmOnclick} classNames={{root:clasees.btn}} color="red" loading={loading}> Yes</Button>
        </Flex>
      </Box>
    </Modal>
  );
};

export default ConfirmationModel;
