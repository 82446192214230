import { useMemo } from "react";
import { useGetAllCourseNames } from "../hooks/courses/query/useGetAllCourseNames.query";
interface IcourseName {
  _id: string;
  name: string;
}
const GetAllCourseName = () => {
  const { data } = useGetAllCourseNames();

  const courseNames: IcourseName[] = useMemo(() => {
    if (data?.status === "success") {
      return data.data;
    }
    return [];
  }, [data]);
  const selectData = courseNames.map((course) => ({
    label: course.name,
    value: course._id,
  }));
  return selectData;
};

export default GetAllCourseName;
