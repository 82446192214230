import { useMutation, useQueryClient } from "@tanstack/react-query";
import { request } from "../../../services/axios.service";
import apiurls from "../../apiurls";
import { IFaq } from "../../../Types/faq.interface";

const update = async ({ data, _id }: { data: IFaq; _id: string }) => {
  const res: TServerResponse = await request({
    url: apiurls.faqApi.EDIT_FAQ + `/${_id}`,
    method: "POST",
    data,
  });
  return res;
};

export const useUpdateFaq = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: update,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["get-all-faq"] });
    },
  });
};
