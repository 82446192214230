import * as Yup from "yup";

const initialValues: IYoutubePlaylist = {
  title: "",
  heading: "",
  descripction: "",
  youtubeLink: "",
  banner: null,
};
const validationSchema = Yup.object().shape({
  title: Yup.string().required("Title is required"),
  heading: Yup.string().required("Heading name is required"),
  descripction: Yup.string().required("Descripction name is required"),
  youtubeLink: Yup.string().required("Youtube link name is required"),
  banner: Yup.mixed()
    .required("Banner is required")
    .test(
      "fileOrString",
      "Banner must be a valid file or string",
      (value) => typeof value === "string" || value instanceof File
    ),
});
export const youtubePlaylist_form = {
  initialValues,
  validationSchema,
};
