import { TTableColumns } from "../../Types/table.interface";
import { IoverallHome } from "../../Types/overallHomePage.interface";
export const overallHomePageColumn: TTableColumns<IoverallHome>[] = [
  {
    key: "sno",
    label: "S. No.",
  },
  {
    key: "enrolledStudents",
    label: "Enrolled Students",
  },
  {
    key: "totalLectureHours",
    label: "Total Hours",
  },
  {
    key: "averageRatings",
    label: "Average Ratings",
  },
  {
    key: "linkedinSubscribers",
    label: "Linkedin Follower Count",
  },
  {
    key: "youtubeSubscribers",
    label: "Youtube Subscriber Count",
  },
];
