import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useFetchCourseById } from "../../hooks/courses/mutation/useGetSingleCourse.mutation";
import { useCreateCourse } from "../../hooks/courses/mutation/useCreateCourse.mutation";
import { useUpdateCourse } from "../../hooks/courses/mutation/useUpdateCourse.mutation";
import { useForm, yupResolver } from "@mantine/form";
import { ICourses } from "../../Types/courses.interface";
import { formValue } from "../../form-values";
import { notifications } from "@mantine/notifications";
import {
  Box,
  Button,
  Divider,
  Flex,
  Group,
  NumberInput,
  Select,
  Text,
  TextInput,
} from "@mantine/core";
import ImageCropInput2 from "../../container/ImageCropPicker/ImageCropInput2";
import RichTextEditorWrapper from "../../components/RichTextEditor/RichTextEditorWrapper";

const AddCourse = () => {
  const { id } = useParams();
  const isEditing = Boolean(id);
  const [loading, setLoading] = useState(false);
  const { mutateAsync: fetchCourse } = useFetchCourseById();
  const { mutateAsync: createCourse } = useCreateCourse();
  const { mutateAsync: updateCourse } = useUpdateCourse();
  const navigate = useNavigate();

  const form = useForm<ICourses>({
    initialValues: formValue.course_form.initialValues,
    validate: yupResolver(formValue.course_form.validationSchema),
  });

  useEffect(() => {
    if (isEditing) {
      setLoading(true);
      fetchCourse({ id: id! })
        .then((res) => {
          const courseData = res.data;
          form.setValues({
            name: courseData?.name,
            duration: courseData?.duration,
            endTime: courseData?.endTime,
            averageRatings: courseData?.averageRatings,
            bannerImage: courseData?.bannerImage || null,
            studentsEnrolled: courseData?.studentsEnrolled,
            plans: courseData?.plans?.map((itm: any) => ({
              courseId: itm?.courseId?.courseId,
              name: itm?.courseId?.name,
              description: itm?.courseId?.description,
              packageName: itm?.courseId?.packageName,
              packageType: itm?.courseId?.packageType,
              price: itm?.courseId?.price,
              additionalInfo: itm?.courseId?.additionalInfo,
            })),
          });
        })
        .catch(() => {
          notifications.show({
            title: "Error",
            message: "Failed to fetch course data.",
            color: "red",
          });
        })
        .finally(() => setLoading(false));
    }
  }, [isEditing, fetchCourse, id]);
  const addPlan = () => {
    form.insertListItem("plans", {
      name: "",
      description: "",
      packageName: "",
      packageType: "",
      price: null,
    });
  };

  const removePlan = (index: number) => {
    form.removeListItem("plans", index);
  };
  const onSubmit = async (values: ICourses) => {
    try {
      const updatedValues = {
        ...values,
        plans: values.plans.map((plan) => ({
          ...plan,
          name: values.name, // Set the plan name to the course name
        })),
      };
      if (isEditing) {
        const update = await updateCourse({
          data: { data: updatedValues, id: id ?? "" },
        });
        if (update.status === "success") {
          notifications.show({
            title: "Course Updated",
            message: "Your Course has been successfully updated.",
            color: "green",
          });
          form.reset();
          navigate("/courses");
        }
      } else {
        const res = await createCourse(updatedValues);
        if (res.status === "success") {
          notifications.show({
            title: "Course Created",
            message: "Your course has been successfully created.",
            color: "green",
          });
          form.reset();
          navigate("/courses");
        }
      }
    } catch (error: any) {
      notifications.show({
        title: "Error",
        message:
          error.response?.data?.message || "An unexpected error occurred.",
        color: "red",
      });
    }
  };
  return (
    <Box style={{ maxWidth: 800, margin: "2rem auto" }}>
      <Text size="xl">{isEditing ? "Edit Course" : "Add Course"}</Text>
      {loading ? (
        <Text>Loading...</Text>
      ) : (
        <form
          style={{ display: "flex", flexDirection: "column", gap: "1rem" }}
          onSubmit={form.onSubmit(onSubmit)}
        >
          <Flex gap="md" align="flex-start">
            <TextInput
              label="Course Name"
              {...form.getInputProps("name")}
              placeholder="Enter course name"
              withAsterisk
            />
            <TextInput
              label="Course Duration"
              {...form.getInputProps("duration")}
              placeholder="Enter course duration"
              withAsterisk
            />
          </Flex>
          <TextInput
            label="End Time"
            {...form.getInputProps("endTime")}
            placeholder="Enter Coures End time"
            withAsterisk
          />
          <ImageCropInput2
            form={form}
            name="bannerImage"
            label="Course Image"
            accept="image/*"
            aspectRatio={1280 / 720}
          />

          <Flex gap={"lg"}>
            <TextInput
              label="Students Enrolled"
              placeholder="Enter students enrolled"
              {...form.getInputProps("studentsEnrolled")}
              withAsterisk
            />
            <TextInput
              label="Average Ratings"
              placeholder="Enter the average ratings"
              {...form.getInputProps("averageRatings")}
              withAsterisk
            />
          </Flex>
          {form.values.plans.map((plan, index) => (
            <Box
              key={index}
              mt="lg"
              style={{ display: "flex", flexDirection: "column", gap: "1rem" }}
            >
              <Divider label={`Plan ${index + 1}`} />
              <TextInput
                label="Package Name"
                {...form.getInputProps(`plans.${index}.packageName`)}
                placeholder="Enter package name"
                withAsterisk
              />
              <Select
                label="Package Type"
                data={[
                  { value: "single", label: "Single" },
                  { value: "combo", label: "Combo" },
                ]}
                placeholder="Select Package type"
                {...form.getInputProps(`plans.${index}.packageType`)}
                withAsterisk
              />
              <NumberInput
                label="Plan Price"
                {...form.getInputProps(`plans.${index}.price`)}
                placeholder="Enter package price"
                withAsterisk
              />

              <Text>
                Plan Description <span style={{ color: "red" }}>*</span>
              </Text>
              <RichTextEditorWrapper
                value={form.values.plans[index].description}
                onChange={(value) =>
                  form.setFieldValue(`plans.${index}.description`, value)
                }
              />
              <Text>Additional Information</Text>
              <RichTextEditorWrapper
                value={form.values.plans[index].additionalInfo}
                onChange={(value) =>
                  form.setFieldValue(`plans.${index}.additionalInfo`, value)
                }
              />
              {index > 0 && (
                <Button
                  mt="sm"
                  color="red"
                  onClick={() => removePlan(index)}
                  variant="light"
                >
                  {form.values.plans.length > 1
                    ? "Remove Section"
                    : "Remove Page"}
                </Button>
              )}
              <Divider my="sm" />
            </Box>
          ))}
          <Group mt="xl">
            {form.values.plans.length < 2 && (
              <Button onClick={addPlan}>Add Plan</Button>
            )}
            <Button type="submit">
              {isEditing ? "Update Course" : "Create Course"}
            </Button>
          </Group>
        </form>
      )}
    </Box>
  );
};

export default AddCourse;
