import { useMutation, useQueryClient } from "@tanstack/react-query";
import apiurls from "../../apiurls";
import { request } from "../../../services/axios.service";
import { ICourses } from "../../../Types/courses.interface";

const createCourse = async (data: ICourses) => {
  const res: TServerResponse = await request({
    headers: { "Content-Type": "multipart/form-data" },
    url: apiurls.coursesApi.ADD_COURSE,
    method: "POST",
    data,
  });
  return res;
};

export const useCreateCourse = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: createCourse,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["courses"] });
    },
  });
};
