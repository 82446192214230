import { Box, Button, Flex, Text } from "@mantine/core";
import React, { useMemo, useState } from "react";
import PaginatedTable from "../../components/paginated-table/PaginatedTable";
import { TTableColumns } from "../../Types/table.interface";
import { CONSTANTS } from "../../constants/index.constant";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { ROUTES } from "../../enum/routes";
import { generateCourseTable } from "../../constants/tables/courses.table";
import { useDeleteCourse } from "../../hooks/courses/mutation/useDeleteCourse.mutation";
import { useGetPaginatedCourses } from "../../hooks/courses/query/useGetPaginatedCourses.query";

const Courses = () => {
  const [page, setPage] = useState(1);
  const { data, isLoading } = useGetPaginatedCourses({
    page,
    limit: CONSTANTS.PAGE_LIMIT,
  });
  const { mutateAsync: deleteCourse } = useDeleteCourse();
  const navigate = useNavigate();
  const location = useLocation();
  const details = useMemo(() => {
    if (!isLoading && data?.status === "success") {
      return (
        (data?.data as []).map((item, index) => ({
          ...(item as unknown as object),
          sno: ((page ?? 1) - 1) * CONSTANTS.PAGE_LIMIT + (index + 1),
        })) ?? []
      );
    }
    return [];
  }, [isLoading, data, page]);
  const columns = useMemo(
    () => generateCourseTable(deleteCourse, navigate),
    [deleteCourse, navigate]
  );
  const isChildRoute = location.pathname !== ROUTES.COURSES;
  return (
    <Box pr={24}>
      {isChildRoute ? (
        <Outlet />
      ) : (
        <>
          <Flex justify={"space-between"} align={"center"}>
            <Text fw={600} fz={"h3"} my={24}>
              Courses
            </Text>
            <Button
              color="blue"
              onClick={() => navigate(`${ROUTES.ADD_COURSE}`)}
            >
              Add New Course
            </Button>
          </Flex>
          <Box>
            <PaginatedTable
              columns={columns as TTableColumns<unknown>[]}
              data={details}
              isLoading={isLoading}
              keyExtractor={(e) => {
                const key = e as { _id: string };
                return key._id;
              }}
              paginationProps={{
                page,
                setPage,
                totalDocuments: Number(data?.extraData ?? "0"),
                pageLimit: CONSTANTS.PAGE_LIMIT,
              }}
            />
          </Box>
        </>
      )}
    </Box>
  );
};

export default Courses;
