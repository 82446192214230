import moment from "moment";
import { INewsletterTable, TTableColumns } from "../../Types/table.interface";

export const newsletterTable: TTableColumns<INewsletterTable>[] = [
  {
    key: "sno",
    label: "S No",
  },
  {
    key: "email",
    label: "Email",
    renderCell: (value) => value.email,
  },

  {
    key: "date",
    label: "Date",
    renderCell: (value) =>
      moment(value.createdAt).format("DD-MMM-YYYY - hh:mm:ss A"),
  },
];
