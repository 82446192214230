import React, { useEffect, useState } from "react";
import axios from "axios";
import { Box } from "@mantine/core";
import { notifications } from "@mantine/notifications";

const AuthCallback: React.FC = () => {

  const [refresh, setRefresh] = useState<string>()

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const code = params.get("code");
    if (code) {
      axios
        .get(`${process.env.REACT_APP_BASE_URL}}/auth/callback?code=${code}`)
        .then((response) => {
          setRefresh(response.data.data)
          notifications.show({message:response.data.message, color:"green"})
        })
        .catch((error) => {
          notifications.show({message:error.message, color:"red"})
        });
    } else {
      console.log("No code found in the URL.");
    }
  }, []);


  return <Box mt={20}>Refresh Token :- {refresh} </Box>;
};

export default AuthCallback;
