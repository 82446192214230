import { Box, Flex, Select, Text, TextInput } from "@mantine/core";
import React, { Fragment, useMemo, useState } from "react";
import PaginatedTable from "../../components/paginated-table/PaginatedTable";
import { TTableColumns } from "../../Types/table.interface";
import { CONSTANTS } from "../../constants/index.constant";
import { useDebouncedState } from "@mantine/hooks";
import { TABLE_COLUMN } from "../../constants/tables";
import { useGetRegisteredStudents } from "../../hooks/transaction/query/useGetRegisteredStudents.query";
import GetAllCourseName from "../../helper/GetAllCourseName";

const Transaction = () => {
  const [page, setPage] = useState(1);
  const [search, setSearch] = useDebouncedState("", 300);
  const courseNames = GetAllCourseName();
  const [selectedCourse, setSelectedCourse] = useState<string>(
    courseNames && courseNames[0]?.label
  );

  const handleCourseChange = (value: any) => {
    // Find the label from courseNames based on the selected value
    const selectedCourse = courseNames.find((course) => course.value === value);
    setSelectedCourse(selectedCourse?.label || "");
  };
  const { data, isLoading } = useGetRegisteredStudents({
    page,
    search,
    name: selectedCourse as string,
  });

  const { data: tableData, total } = useMemo(() => {
    if (!isLoading && data?.data) {
      const serialized =
        (data?.data as []).map((item, index) => ({
          ...(item as unknown as object),
          sno: ((page ?? 1) - 1) * CONSTANTS.PAGE_LIMIT + (index + 1),
        })) ?? [];
      return {
        data: serialized,
        total: data.extraData,
      };
    }
    return { data: [], total: 0 };
  }, [data, isLoading]);

  return (
    <Fragment>
      <Box pr={24}>
        <Flex justify={"space-between"} align={"center"}>
          <Text fw={600} fz={"h3"} my={24}>
            Student Details who purchased the course
          </Text>
        </Flex>
        <Flex justify={"space-between"} align={"center"} gap={20}>
          <Select
            mb={12}
            label={"Select Course Name"}
            placeholder="Eg. Advanced Valuation and Financial Modelling"
            data={courseNames}
            defaultValue={selectedCourse}
            value={
              courseNames.find((course) => course.label === selectedCourse)
                ?.value
            }
            onChange={handleCourseChange}
          />
          <TextInput
            onChange={(value) => setSearch(value.target.value)}
            placeholder="Search student"
            styles={{ root: { width: 218 } }}
          />
        </Flex>
        <Box>
          <PaginatedTable
            columns={
              TABLE_COLUMN.registeredStudentsColumn as TTableColumns<unknown>[]
            }
            data={tableData}
            isLoading={isLoading}
            keyExtractor={(e) => {
              const key = e as { _id: string };
              return key._id;
            }}
            paginationProps={{
              page,
              setPage,
              totalDocuments: Number(total),
              pageLimit: CONSTANTS.PAGE_LIMIT,
            }}
          />
        </Box>
      </Box>
    </Fragment>
  );
};

export default Transaction;
