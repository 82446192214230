import { useQuery } from "@tanstack/react-query";
import apiurls from "./apiurls";
import { request } from "../services/axios.service";
type TProps = {
  page: number;
  limit: number;
};
const getContacts = async (data: TProps) => {
  const res: TServerResponse = await request({
    url: apiurls.GET_CONTACT,
    method: "GET",
    params: data,
  });
  return res;
};

export const useGetContacts = (data: TProps) => {
  return useQuery({
    queryKey: ["newsletter", data],
    queryFn: () => getContacts(data),
  });
};
