import { useQuery } from "@tanstack/react-query";
import { request } from "../../../services/axios.service";
import apiurls from "../../apiurls";

const getCategories = async () => {
  const res: TServerResponse = await request({
    url: apiurls.FETCH_CATEGORIES,
    method: "GET",
  });
  return res;
};

export const useFetchCategories = () => {
  return useQuery({
    queryKey: ["categories"],
    queryFn: () => getCategories(),
  });
};
