import { memo } from "react";
import { ActionIcon, Flex, Tooltip } from "@mantine/core";
import {
  IconEdit,
  IconEye,
  IconEyeClosed,
  IconTrash,
} from "@tabler/icons-react";
import AlumniModal from "./AlumniModal";
import useDeleteAlumni from "../../../hooks/alumni/useDeleteAlumni";
import { notifications } from "@mantine/notifications";
import { queryClient } from "../../../App";
import useChangeAlumniStatus from "../../../hooks/alumni/useChangeAlumniStatus";
import { Modals, confirmationAlert } from "../../../components/Modals/TModals";

interface IProps {
  id: string;
  isActive?: boolean;
}

const ActionButtonAlumni: React.FC<IProps> = ({ id, isActive }) => {
  const { mutateAsync } = useDeleteAlumni();
  const { mutateAsync: mutateAsyncStatus } = useChangeAlumniStatus();

  const handleEdit = () => {
    Modals({
      title: "Edit Custom Pricing",
      children: <AlumniModal isCreate={false} id={id} />,
    });
  };

  const handleCancel = async () => {
    const confirm = await confirmationAlert({
      title: "Confirm Deleting",
      labels: { cancel: "Cancel", confirm: "Confirm" },
      msg: "Are you sure you want to delete this alumni?",
    });
    if (!confirm) return;

    const response = await mutateAsync(id);
    if (response.status === "error") {
      return notifications.show({
        message: response.message,
        color: "red",
      });
    }
    queryClient.invalidateQueries({ queryKey: ["alumni-get-all"] });
    return notifications.show({
      message: response.message,
      color: "green",
    });
  };

  const handleStatusChange = async () => {
    const confirm = await confirmationAlert({
      title: "Confirm Status Change",
      labels: { cancel: "Cancel", confirm: "Confirm" },
      msg: "Are you sure you want to change status of this alumni?",
    });
    if (!confirm) return;

    const response = await mutateAsyncStatus({ id, status: !isActive });
    if (response.status === "error") {
      return notifications.show({
        message: response.message,
        color: "red",
      });
    }
    queryClient.invalidateQueries({ queryKey: ["alumni-get-all"] });
    return notifications.show({
      message: response.message,
      color: "green",
    });
  };

  return (
    <div>
      <Flex justify={"space-between"} gap={20} align={"center"}>
        {/* <Tooltip label={"Change Alumni Status"}>

        <ActionIcon variant="outline" onClick={handleStatusChange}>
        {isActive ? (
              <IconEye style={{ width: "70%", height: "70%" }} stroke={1.5} />
            ) : (
              <IconEyeClosed style={{ width: "70%", height: "70%" }} stroke={1.5} />
            )}
        </ActionIcon>
        </Tooltip> */}
        <Tooltip label="Edit Alumni">
          <ActionIcon variant="outline" onClick={() => handleEdit()}>
            <IconEdit style={{ width: "70%", height: "70%" }} stroke={1.5} />
          </ActionIcon>
        </Tooltip>
        <Tooltip label={"Delete Alumni"}>
          <ActionIcon
            variant="outline"
            color="red"
            onClick={() => handleCancel()}
          >
            <IconTrash style={{ width: "70%", height: "70%" }} stroke={1.5} />
          </ActionIcon>
        </Tooltip>
      </Flex>
    </div>
  );
};

export default memo(ActionButtonAlumni);
