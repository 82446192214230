import { useMutation } from "@tanstack/react-query";
import apiurls from "../../apiurls";
import { request } from "../../../services/axios.service";

type TProps = {
  id: string;
};
const fetchCourse = async (data: TProps) => {
  const res: TServerResponse = await request({
    url: apiurls.coursesApi.GET_SINGLE_COURSE + `/${data.id}`,
    method: "POST",
  });
  return res;
};

export const useFetchCourseById = () => {
  return useMutation({
    mutationFn: fetchCourse,
  });
};
