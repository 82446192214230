import { useQuery } from "@tanstack/react-query";

import apiurls from "../apiurls";
import { request } from "../../services/axios.service";

const getAlumniById = async (data: { page: number; search: string }) => {
  const res: TServerResponse = await request({
    url: apiurls.alumniUrls.GET_ALL_ALUMNIS,
    method: "GET",
    params: {
      page: data.page,
      search: data.search,
    },
  });
  return res;
};

export default (data: { page: number; search: string }) => {
  return useQuery({
    queryKey: ["alumni-get-all", data.page, data.search],
    queryFn: () => getAlumniById(data),
  });
};
