import { useQuery } from "@tanstack/react-query";
import { request } from "../../services/axios.service";
import apiurls from "../apiurls";

interface Params {
  courseId: string;
}

const get = async (params: Params) => {
  const res: TServerResponse = await request(
    {
      url: apiurls.LEARNING_MODULE.GET_LEARNING_MODULE_BY_COURSE,
      method: "GET",
      params,
    },
    true
  );
  return res;
};

export const useGetLearningModuleByCourseQuery = (params: Params) => {
  return useQuery({
    queryKey: ["admin", "learning-module", "get-by-course", params.courseId],
    queryFn: () => get(params),
  });
};
