import React, { useEffect, useMemo, useState } from "react";
import { TTableColumns } from "../../Types/table.interface";
import PaginatedTable from "../../components/paginated-table/PaginatedTable";
import { Box, Flex, Select, Text, TextInput } from "@mantine/core";
import { CONSTANTS } from "../../constants/index.constant";
import { useGetAllStudents } from "../../hooks/students/queries/useGetAllStudents.query";
import { studentTable } from "../../constants/tables/students.table";
import { useDebouncedState } from "@mantine/hooks";

const StudentsPage = () => {
  const [page, setPage] = useState(1);
  const [search, setSearch] = useDebouncedState("", 300);
  const [text, setText] = useState("");
  const [isKycDone, setIsKycDone] = useState("");
  useEffect(() => {
    setSearch(text);
  }, [text, setSearch]);
  const { data, isLoading } = useGetAllStudents({
    page,
    limit: CONSTANTS.PAGE_LIMIT,
    search: search,
    isKycDone: isKycDone,
  });

  const details = useMemo(() => {
    if (!isLoading && data?.status === "success") {
      return (
        (data?.data as []).map((item, index) => ({
          ...(item as unknown as object),
          sno: ((page ?? 1) - 1) * CONSTANTS.PAGE_LIMIT + (index + 1),
        })) ?? []
      );
    }
    return [];
  }, [isLoading, data, page]);

  return (
    <Box pr={24}>
      <Flex justify={"space-between"} align={"center"}>
        <Text fw={600} fz={"h3"} my={24}>
          Students
        </Text>
        <Flex gap={24}>
          <TextInput
            placeholder="Search by name, email and number..."
            w={"100%"}
            value={text}
            onChange={(e) => {
              setPage(1);
              setText(e.currentTarget.value);
            }}
          />
          <Select
            placeholder="Filter By Kyc"
            data={[
              { value: "yes", label: "Done" },
              { value: "no", label: "No" },
            ]}
            value={isKycDone}
            onChange={(e) => {
              setPage(1);
              setIsKycDone(e ?? "");
            }}
          />
        </Flex>
      </Flex>
      <Box>
        <PaginatedTable
          columns={studentTable as TTableColumns<unknown>[]}
          data={details}
          isLoading={isLoading}
          keyExtractor={(e) => {
            const key = e as { _id: string };
            return key._id;
          }}
          paginationProps={{
            page,
            setPage,
            totalDocuments: Number(data?.extraData ?? "0"),
            pageLimit: CONSTANTS.PAGE_LIMIT,
          }}
        />
      </Box>
    </Box>
  );
};

export default StudentsPage;
