import { useMutation } from "@tanstack/react-query";
import { request } from "../../../services/axios.service";
import apiurls from "../../apiurls";

const create = async (data: IYoutubePlaylist) => {
  const res: TServerResponse = await request({
    headers: { "Content-Type": "multipart/form-data" },
    url: apiurls.YOUTUBE_PLAYLIST_UPDATE,
    method: "POST",
    data,
  });
  return res;
};

export const useUpdateYouTubePlaylist = () => {
  return useMutation({
    mutationFn: create,
  });
};
