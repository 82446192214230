import { useMutation, useQueryClient } from "@tanstack/react-query";
import apiurls from "../../apiurls";
import { request } from "../../../services/axios.service";
import { IoverallHome } from "../../../Types/overallHomePage.interface";

const createDetails = async (data: IoverallHome) => {
  const res: TServerResponse = await request({
    // headers: { "Content-Type": "multipart/form-data" },
    url: apiurls.overallHomePageApi.ADD_DETAILS,
    method: "POST",
    data,
  });
  return res;
};

export const useAddHomePageDetails = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: createDetails,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["home-page"] });
    },
  });
};
