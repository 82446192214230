import { ActionIcon, Flex, Tooltip } from "@mantine/core";
import { IconEdit } from "@tabler/icons-react";
import React from "react";
import { memo } from "react";
// import { ConfirmationAlert } from "../ConfirmationAlert/confirmationAlert";
// import { ConfirmationAlert } from "../ConfirmationAlert/ConfirmationAlert";

interface Iprops {
  name: string;
  handleDelete: () => void;
  handleEdit: () => void;
  handleToggleActive?: () => void;
  value: any;
  isPending?: boolean;
}
const CommonActionBar: React.FC<Iprops> = ({
  name,
  handleDelete,
  handleEdit,
  isPending,
}) => {
  return (
    <div>
      <Flex gap="xs" justify="center">
        <Tooltip label="Edit">
          <ActionIcon onClick={handleEdit}>
            <IconEdit />
          </ActionIcon>
        </Tooltip>
        {/* <Tooltip label="Delete">
          <ActionIcon
            color="red"
            onClick={() =>
              ConfirmationAlert({
                title: `Delete ${name}`,
                msg: `Are you sure want to delete this ${name}?`,
                labels: { cancel: "cancel", confirm: "Delete" },
                onConfirm: handleDelete,
              })
            }
            type="button"
            px={2}
            py={3}
            loading={isPending}
          >
            <IconTrash />
          </ActionIcon>
        </Tooltip> */}
        {/* <Tooltip label={value.isActive ? "Deactivate" : "Activate"}>
          <ActionIcon color="gray" onClick={handleToggleActive}>
            {value.isActive ? <IconEye /> : <IconEyeOff />}
          </ActionIcon>
        </Tooltip> */}
      </Flex>
    </div>
  );
};

export default memo(CommonActionBar);
