import * as Yup from "yup";

const initialValues = {
    description: "",
    imageUrl: "",
    linkedinUrl: "",
    name: "",
    position: "",
    rating: 0,
};
const validationSchema = Yup.object().shape({
    name: Yup.string().required("Name is required"),
    position: Yup.string().required("Position is required"),
    rating: Yup.number().required("Rating is required").min(1, "Rating is required"),
    description: Yup.string().required("Description is required"),
    imageUrl: Yup.string().required("Image url is required"),
    linkedinUrl: Yup.string().required("Linkedin profile is required"),
    _id: Yup.string().optional(),
 
});
export const alumnis_form = {
  initialValues,
  validationSchema,
};
