import { useMutation, useQueryClient } from "@tanstack/react-query";
import apiurls from "../../apiurls";
import { request } from "../../../services/axios.service";
import { IBlog } from "../../../Types/table.interface";

const updateBlog = async (data: IBlog) => {
  const res: TServerResponse = await request({
    headers: { "Content-Type": "multipart/form-data" },
    url: apiurls.EDIT_BLOG,
    method: "POST",
    data: { ...data, categoryId: data.category },
  });
  return res;
};

export const useUpdateBlog = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: updateBlog,
    onSuccess: () => {
      // Refetch the allBlog query
      queryClient.invalidateQueries({ queryKey: ["blogs"] }); // Replace "allBlog" with your actual query key
    },
  });
};
