import React, { useMemo, useState } from "react";
import { Box, Button, Flex, Text, TextInput } from "@mantine/core";
import { TTableColumns } from "../../Types/table.interface";
import { IconPlus } from "@tabler/icons-react";
import { useDebouncedState } from "@mantine/hooks";
import { TABLE_COLUMN } from "../../constants/tables";
import { Modals } from "../../components/Modals/TModals";
import PaginatedTable from "../../components/paginated-table/PaginatedTable";
import AlumniModal from "./components/AlumniModal";
import useGetAllAlumni from "../../hooks/alumni/useGetAllAlumni";
import { CONSTANTS } from "../../constants/index.constant";

const Alumnis = () => {
  const [page, setPage] = useState(1);
  const [search, setSearch] = useDebouncedState("", 300);

  const { data, isLoading } = useGetAllAlumni({ page, search });

  const { data: tableData, total } = useMemo(() => {
    if (!isLoading && data?.data) {
      const serialized =
        (data?.data.alumnis.data as []).map((item, index) => ({
          ...(item as unknown as object),
          sno: ((page ?? 1) - 1) * CONSTANTS.PAGE_LIMIT + (index + 1),
        })) ?? [];
      return {
        data: serialized,
        total: data.data.alumnis.totalDocuments,
      };
    }
    return { data: [], total: 0 };
  }, [data, isLoading]);

  const handleAddModal = () => {
    Modals({
      children: <AlumniModal isCreate />,
      title: "Add New Alumni",
    });
  };

  return (
    <Box pr={24}>
      <Flex my={24} justify={"space-between"} align={"center"}>
        <Text fw={600} fz={"h3"}>
          Alumni Management
        </Text>

        <Flex justify={"center"} align={"center"} gap={20}>
          <TextInput
            onChange={(value) => setSearch(value.target.value)}
            placeholder="Search Alumnis.."
          />
          <Button
            onClick={() => handleAddModal()}
            leftSection={<IconPlus />}
            variant="filled"
          >
            Add Alumni
          </Button>
        </Flex>
      </Flex>

      <Box>
        <PaginatedTable
          columns={TABLE_COLUMN.alumnicolumn as TTableColumns<unknown>[]}
          data={tableData}
          isLoading={isLoading}
          keyExtractor={(e) => {
            const key = e as { _id: string };
            return key._id;
          }}
          paginationProps={{
            page,
            setPage,
            totalDocuments: Number(total),
            pageLimit: CONSTANTS.PAGE_LIMIT,
          }}
        />
      </Box>
    </Box>
  );
};

export default Alumnis;
