import { Box, Button, Flex, Text } from "@mantine/core";
import React, { Fragment, useMemo, useState } from "react";
import PaginatedTable from "../../components/paginated-table/PaginatedTable";
import { TTableColumns } from "../../Types/table.interface";
import { CONSTANTS } from "../../constants/index.constant";
import { useDisclosure } from "@mantine/hooks";
import { TABLE_COLUMN } from "../../constants/tables";
import ActionButton from "./components/action-button/ActionButton";
import { IconPlus } from "@tabler/icons-react";
import { useGetPaginatedHomeDetails } from "../../hooks/overall-home-page/query/useGetPaginatedHomeDetails.query";
import LandingPageModel from "./components/model/LandingPageModel";

const LandingPage = () => {
  const [opened, { open, close }] = useDisclosure(false);
  const [page, setPage] = useState(1);
  const { data, isLoading, refetch } = useGetPaginatedHomeDetails({
    page,
    limit: CONSTANTS.PAGE_LIMIT,
  });

  const combinedColumns = useMemo(() => {
    const newColumns = {
      key: "Action",
      label: "Action",
      minWidth: 300,
      renderCell: (value: any) => (
        <ActionButton data={value} refetch={refetch} />
      ),
    };

    return [
      ...(TABLE_COLUMN.overallHomePageColumn as TTableColumns<unknown>[]),
      newColumns,
    ];
  }, [refetch]);

  const details = useMemo(() => {
    if (!isLoading && data?.status === "success") {
      return (
        (data?.data as []).map((item, index) => ({
          ...(item as unknown as object),
          sno: ((page ?? 1) - 1) * CONSTANTS.PAGE_LIMIT + (index + 1),
        })) ?? []
      );
    }
    return [];
  }, [isLoading, data, page]);
  return (
    <Fragment>
      <Box pr={24}>
        <Flex justify={"space-between"} align={"center"}>
          <Text fw={600} fz={"h3"} my={24}>
            Landing Page
          </Text>
          {details.length > 0 ? (
            <></>
          ) : (
            <Button
              leftSection={<IconPlus />}
              onClick={open}
              disabled={details.length > 0}
            >
              Add Home page Details
            </Button>
          )}
        </Flex>
        <Box>
          <PaginatedTable
            columns={combinedColumns}
            data={details}
            isLoading={isLoading}
            keyExtractor={(e) => {
              const key = e as { _id: string };
              return key._id;
            }}
            paginationProps={{
              page,
              setPage,
              totalDocuments: Number(data?.extraData ?? "0"),
              pageLimit: CONSTANTS.PAGE_LIMIT,
            }}
          />
        </Box>
      </Box>
      <LandingPageModel
        data={{ close: close, opened: opened, refetch: refetch }}
      />
    </Fragment>
  );
};

export default LandingPage;
