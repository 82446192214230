import { useQuery } from "@tanstack/react-query";
import { request } from "../../../services/axios.service";
import apiurls from "../../apiurls";

const getCourses = async () => {
  const res: TServerResponse = await request({
    url: apiurls.coursesApi.GET_COURSE_NAMES,
    method: "GET",
  });
  return res;
};

export const useGetAllCourseNames = () => {
  return useQuery({
    queryKey: ["get-all-course-names"],
    queryFn: () => getCourses(),
  });
};
