import React from "react";
interface Iprops {
  value: any;
}
const BlogImage = ({ value }: Iprops) => {
  return (
    <img src={value.cardData.image} width={50} height={50} alt="card_image" />
  );
};

export default BlogImage;
