import { ScrollArea, Table, Loader, Center } from "@mantine/core";
import React, { memo } from "react";
import PaginationComponent from "./components/PaginationComponent";
import { ITableProps } from "../../Types/table.interface";

const PaginatedTable: React.FC<ITableProps<unknown>> = (props) => {
  const {
    columns,
    data,
    keyExtractor,
    paginationProps,
    isLoading,
    limitProps = undefined,
  } = props;

  return (
    <div>
      <ScrollArea>
        {isLoading ? (
          <Center mih={"20rem"}>
            <Loader size="md" />
          </Center>
        ) : (
          <Table highlightOnHover withTableBorder withColumnBorders striped>
            <Table.Thead>
              <Table.Tr>
                {columns.map((e) => (
                  <Table.Th ta={"center"} key={e.key} miw={e.minWidth}>
                    {e.label}
                  </Table.Th>
                ))}
              </Table.Tr>
            </Table.Thead>
            {data.length === 0 ? (
              <Table.Tr style={{}}>
                <Table.Td
                  colSpan={columns?.length}
                  style={{ textAlign: "center", fontWeight: 500 }}
                >
                  No Data Found
                </Table.Td>
              </Table.Tr>
            ) : (
              <Table.Tbody>
                {data.map((e: any) => (
                  <Table.Tr key={keyExtractor(e)}>
                    {columns.map((val) => (
                      <Table.Td
                        ta={"center"}
                        key={`${keyExtractor(e)}-${val.key}`}
                      >
                        {val.renderCell
                          ? val.renderCell(e)
                          : e?.[val.key] ?? ""}
                      </Table.Td>
                    ))}
                  </Table.Tr>
                ))}
              </Table.Tbody>
            )}
          </Table>
        )}
      </ScrollArea>
      {paginationProps?.page && (
        <PaginationComponent
          paginationProps={{ ...paginationProps }}
          limitProps={{ ...limitProps }}
        />
      )}
    </div>
  );
};

export default memo(PaginatedTable);
