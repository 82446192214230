import { modals } from "@mantine/modals";
import { ReactNode } from "react";
import { Text } from "@mantine/core";

export type Props = {
  title?: string;
  msg?: string;
  labels?: { confirm: string; cancel: string };
  onConfirm?: () => void;
};
export const confirmationAlert = ({
  labels = { cancel: "Cancel", confirm: "Confirm" },
  title = "Are you sure you want to continue?",
  ...data
}: Props): Promise<boolean> => {
  return new Promise((resolve) => {
    modals.openConfirmModal({
      title: title,
      centered: true,
      children: <Text>{data.msg}</Text>,
      labels: labels,
      confirmProps: { color: "red" },
      onConfirm: () => {
        resolve(true);
      },
      onCancel: () => {
        resolve(false);
      },
    });
  });
};

export const Modals = ({
  title,
  children,
  centered,
  size = "lg",
}: {
  title: string;
  children: ReactNode;
  centered?: boolean;
  size?: number | string;
}) => {
  modals.open({
    title: <Text>{title}</Text>,
    children: children,
    size: size,
    styles: { root: { maxWidth: "400px" } },
    centered: centered,
  });
};
