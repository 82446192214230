import { useMutation, useQueryClient } from "@tanstack/react-query";
import apiurls from "../../apiurls";
import { request } from "../../../services/axios.service";

const deleteCourse = async (id: string) => {
  const res: TServerResponse = await request({
    url: apiurls.coursesApi.DELETE_COURSE + `/${id}`,
    method: "POST",
  });
  return res;
};

export const useDeleteCourse = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: deleteCourse,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["courses"] });
    },
  });
};
