import React from "react";
import { IContactTable } from "../Types/table.interface";
import { Text } from "@mantine/core";
interface IProps {
  value: IContactTable;
}
const ContactFile = ({ value }: IProps) => {
  if (value.emailSubject === "paymentIssue") {
    return <img src={value.fileUrl} width={50} height={50} alt="issue" />;
  } else if (value.emailSubject === "forHiring") {
    return (
      <a href={value.fileUrl} download target="_blank">
        LINK
      </a>
    );
  } else return <Text fz={14}>N/A</Text>;
};

export default ContactFile;
