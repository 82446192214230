import { ActionIcon, Flex } from "@mantine/core";
import { IconTrash, IconEdit } from "@tabler/icons-react";
import React, { Fragment } from "react";
import { notifications } from "@mantine/notifications";
import ConfirmationModel from "../../../../components/ui/confirmation-model/ConfirmationModel";
import { useDisclosure } from "@mantine/hooks";
import JoyResultFormModel from "../model/JoyResultFormModel";
import { useDeleteJoyResult } from "../../../../hooks/joy-result/mutation/deleteJoyResult.mutation";

interface IActionButton {
  data: IYoutubePlaylist;
  refetch: any;
}

const ActionButton: React.FC<IActionButton> = ({ data, refetch }) => {
  const [opened, { open, close }] = useDisclosure(false);
  const [editOpened, { open: openEdit, close: closeEdit }] =
    useDisclosure(false);
  const { mutateAsync, isPending } = useDeleteJoyResult();
  const handleAdminSubmit = async (id: string) => {
    try {
      const res = await mutateAsync({ _id: id });
      if (res.statusCode === 200) {
        notifications.show({ message: res.message, color: "green" });
        close();
        refetch();
      }
    } catch (error: any) {
      notifications.show({ message: error.message, color: "red" });
    }
  };
  return (
    <Fragment>
      <Flex justify={"center"} align={"center"} gap={20}>
        {/* <ActionIcon variant="outline" color="red" onClick={open}>
          <IconTrash style={{ width: "70%", height: "70%" }} stroke={1.5} />
        </ActionIcon> */}

        <ActionIcon variant="outline" onClick={openEdit}>
          <IconEdit style={{ width: "70%", height: "70%" }} stroke={1.5} />
        </ActionIcon>
      </Flex>
      <ConfirmationModel
        data={{
          close: close,
          opened: opened,
          confirmOnclick: () => handleAdminSubmit(data?._id as string),
          loading: isPending,
        }}
      />
      <JoyResultFormModel
        data={{
          close: closeEdit,
          opened: editOpened,
          value: data,
          refetch: refetch,
        }}
      />
    </Fragment>
  );
};

export default ActionButton;
