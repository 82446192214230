import { alumnicolumn } from "./alumni.column";
import { courseReviewcolumn } from "./courseReviews.column";
import { faqColumn } from "./faq.column";
import { joyResultcolumn } from "./joyResult.column";
import { overallHomePageColumn } from "./overallHomePage.column";
import { registeredStudentsColumn } from "./registeredStudent.column";
import { youtubePlayListcolumn } from "./youtubePlaylist.column";

export const TABLE_COLUMN = {
  youtubePlayListcolumn: youtubePlayListcolumn,
  joyResultcolumn: joyResultcolumn,
  alumnicolumn: alumnicolumn,
  courseReviewcolumn: courseReviewcolumn,
  faqColumn: faqColumn,
  overallHomePageColumn: overallHomePageColumn,
  registeredStudentsColumn: registeredStudentsColumn,
};
