import * as Yup from "yup";

const initialValues: IJoyresult = {
  title:"",
  descripction:"",
  username:"",
  linkedinUrl:"",
  linkedinProfile:"",
  identity:"Student TVS",
};
const validationSchema = Yup.object().shape({
  title: Yup.string().required("Title is required"),
  descripction: Yup.string().required("Descripction is required"),
  username: Yup.string().required("User name is required"),
  linkedinUrl: Yup.string()
  .required("LinkedIn URL is required")
  .url("Please enter a valid LinkedIn URL"),
  linkedinProfile: Yup.string().required("Linkedin Profile is required").url("Please enter a valid linkedin profile URL"),
  identity: Yup.string().required("Identity is required"),
 
});
export const joyresult_form = {
  initialValues,
  validationSchema,
};
