import { Box, Flex, Select, Text } from "@mantine/core";
import React, { useState } from "react";
import ModuleForm from "./components/ModuleForm";
import GetAllCourseName from "../../helper/GetAllCourseName";

const LearningModule: React.FC = () => {
  const courseNames = GetAllCourseName();
  const [selectedCourse, setSelectedCourse] = useState<string>(
    courseNames && courseNames[0]?.value
  );
  const handleCourseChange = (value: any) => {
    setSelectedCourse(value);
  };
  return (
    <Box pr={24}>
      <Flex justify={"space-between"} align={"center"}>
        <Text fw={600} fz={"h3"} my={24}>
          Learning Module{" "}
        </Text>
        <Select
          mb={12}
          label={"Select Course Name"}
          placeholder="Eg. Advanced Valuation and Financial Modelling"
          data={courseNames}
          value={selectedCourse} // Controlled value
          onChange={handleCourseChange}
        />
      </Flex>
      {selectedCourse ? (
        <ModuleForm key={selectedCourse} courseId={selectedCourse} />
      ) : (
        <Box mt={40}>
          <Text size="lg" c="dimmed" style={{ textAlign: "center" }}>
            Please select a course to view the learning module in the top-right
            corner.
          </Text>
        </Box>
      )}
    </Box>
  );
};

export default LearningModule;
