import React, { memo } from "react";
import { NavLink } from "@mantine/core";
import { naves } from "./navs";
import { useMatch, useNavigate } from "react-router-dom";
import { IconChevronRight } from "@tabler/icons-react";

export interface MainLinkProps {
  icon: React.ReactNode;
  color: string;
  label: string;
  to: string;

  children?: MainLinkProps[];
}

const MainLink = memo(
  ({
    icon,
    color,
    label,
    to,
    children,
    rootPath,
    toggle,
  }: MainLinkProps & { rootPath: string; toggle: () => void }) => {
    const navigate = useNavigate();

    const match = useMatch(!children ? rootPath + to : to);

    return (
      <NavLink
        onClick={() => {
          if (!children) {
            navigate(rootPath + to);
            toggle();
          }
        }}
        label={label}
        leftSection={icon}
        rightSection={
          children ? <IconChevronRight size={12} stroke={1.5} /> : undefined
        }
        active={Boolean(match)}
      >
        {children?.map((link) => (
          <MainLink toggle={toggle} rootPath={to} {...link} key={link.label} />
        ))}
      </NavLink>
    );
  }
);

export const MainLinks = ({ toggle }: { toggle: () => void }) => {
  const links = naves.map((link) => (
    <MainLink toggle={toggle} rootPath="" {...link} key={link.label} />
  ));
  return <div>{links}</div>;
};