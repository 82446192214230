import {
  Box,
  Modal,
  Textarea,
  TextInput,
} from "@mantine/core";
import React, { useEffect } from "react";
import FButton from "../../../../components/ui/Button/FButton";
import { useForm, yupResolver } from "@mantine/form";
import { formValue } from "../../../../form-values";
import { notifications } from "@mantine/notifications";
import { useCreateJoyResult } from "../../../../hooks/joy-result/mutation/createJoyResult.mutation";
import { useUpdateJoyResult } from "../../../../hooks/joy-result/mutation/updateJoyResult.mutation";

interface IYoutubePlayListAddModel {
  data: {
    opened: boolean;
    close: () => void;
    value?: IYoutubePlaylist;
    refetch: any;
  };
}

const JoyResultFormModel: React.FC<IYoutubePlayListAddModel> = ({ data }) => {
  const { close, opened, value, refetch } = data;
  const isEditMode = !!value;
  const { mutateAsync, isPending } = useCreateJoyResult();
  const { mutateAsync: updatePlaylist, isPending: isUpdating } =
    useUpdateJoyResult();
  const form = useForm<IJoyresult>({
    initialValues: formValue.joyresult_form.initialValues,
    validate: yupResolver(formValue.joyresult_form.validationSchema),
  });

  useEffect(() => {
    if (value) {
      form.setValues({ ...value }); // Set form values for editing
    }
  }, [value]);

  const handleAdminSubmit = async (e: IJoyresult) => {
    try {
      const res = isEditMode
        ? await updatePlaylist(e) // Update if in edit mode
        : await mutateAsync(e);
      if (res.statusCode === 200) {
        notifications.show({ message: res.message, color: "green" });
        form.reset();
        close();
        refetch();
      }
    } catch (error: any) {
      notifications.show({ message: error.message, color: "red" });
    }
  };

  return (
    <Modal
      opened={opened}
      onClose={close}
      title={isEditMode ? "Edit Joy Result" : "Add Joy Result"}
      closeOnClickOutside={false}
      size={"lg"}
    >
      <form
        onSubmit={form.onSubmit(handleAdminSubmit)}
        style={{ width: "100%" }}
      >
        <Box>
          <TextInput
            mb={12}
            required
            label={"Enter Title"}
            placeholder={"Eg. Teacher"}
            {...form.getInputProps("title")}
          />
          <TextInput
            required
            mb={12}
            label={"Enter Name"}
            placeholder={"Eg. Rohan"}
            {...form.getInputProps("username")}
          />
          <TextInput
            required
            mb={12}
            label={"Enter Linkedin Url"}
            placeholder={
              "Eg. https://www.linkedin.com/in/rajveer-jatav-485045215/"
            }
            {...form.getInputProps("linkedinUrl")}
          />
          <TextInput
            required
            mb={12}
            label={"Enter Linkedin Profile Url"}
            placeholder={
              "Eg. https://media.licdn.com/dms/image/v2/C4E03AQGFCcUjHzHUGQ/profile-displayphoto-shrink_800_800/profile-displayphoto-shrink_800_800/0/1623765359492?e=1737590400&v=beta&t=5eQyanxL_5J-KzmRwDqX4pDNffCX1DrHRdlayOKMnZs"
            }
            {...form.getInputProps("linkedinProfile")}
          />

          <TextInput
            required
            mb={12}
            label={"Enter Identity"}
            placeholder={
              "Eg. Student TVS"
            }
            {...form.getInputProps("identity")}
          />

          <Textarea
            required
            mb={12}
            label={"Enter Descripction"}
            placeholder={
              "Eg. First of all, I would like to express my heartfelt thanks for all the knowledge provided to us through this cohort."
            }
            rows={6}
            {...form.getInputProps("descripction")}
          />
          <FButton
            label={isEditMode ? "Edit Joy Result" : "Add Joy Result"}
            type="submit"
            width="100%"
            loading={isEditMode ? isUpdating : isPending}
          />
        </Box>
      </form>
    </Modal>
  );
};

export default JoyResultFormModel;
